import React from "react";
import { useSelector } from "react-redux";

const IsDisabledForLandlordId = ({
  children,
  disabledForLandlordIds = [],
  enabledForLandlordIds = [],
}) => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  if (!selectedLandlord) {
    return null;
  }

  const selectedLandlordId = selectedLandlord.landlordid;

  if (disabledForLandlordIds.includes(selectedLandlordId)) {
    return null;
  }

  if (enabledForLandlordIds.length > 0) {
    if (!enabledForLandlordIds.includes(selectedLandlordId)) {
      return null;
    }
  }

  return <>{children}</>;
};

export default IsDisabledForLandlordId;
