import { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
  const location = useLocation();
  const observer = useRef(null);

  const scrollToTargetWithRetry = useCallback((targetId, attempts = 3) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      observer.current?.disconnect();
    } else if (attempts > 0) {
      setTimeout(() => scrollToTargetWithRetry(targetId, attempts - 1), 100);
    }
  }, []);

  useEffect(() => {
    const targetId = location.hash.slice(1);

    scrollToTargetWithRetry(targetId);

    const config = { attributes: true, childList: true, subtree: true };
    observer.current = new MutationObserver(() => {
      scrollToTargetWithRetry(targetId);
    });

    observer.current.observe(document.body, config);

    return () => observer.current?.disconnect();
  }, [location, scrollToTargetWithRetry]);

  return null;
}

export default ScrollToAnchor;
