import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../../config";
import NotyfContext from "../../../../contexts/NotyfContext";

const PasswordResetModal = ({ openModals, toggle, passedData }) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const sendPasswordReset = (data) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.credentialsApiUrl + `users/user/${data.username}/pwreset`,
        null,
        axiosConfig
      )
      .then(function (response) {
        if (response.data.success === 1) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.error,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };
  return (
    <Modal
      show={openModals["sendPasswordReset"]}
      onHide={() => toggle("sendPasswordReset")}
      centered
    >
      <Modal.Header closeButton>
        <b>Admin Function:</b>&nbsp; Send Password Reset
      </Modal.Header>
      <Modal.Body className="m-3">
        <p className="text-left my-0">
          You can send a password reset email to <b>{passedData.email}</b> by
          clicking the button below. The user will need to follow the link in
          the email to reset their password.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => toggle("sendPasswordReset")}>
          Close
        </Button>
        <Button
          variant="info"
          onClick={() => {
            toggle("sendPasswordReset");
            sendPasswordReset(passedData);
          }}
        >
          Send Password Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordResetModal;
