import React, { useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import NotyfContext from "../../../contexts/NotyfContext";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../config";

const DeleteSSIDModal = ({
  openModals,
  toggle,
  passedData,
  dispatch,
  setRefreshData,
}) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const deleteSSID = (data) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .delete(apiConfig.credentialsApiUrl + `ssids/${data.id}`, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.error,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };
  return (
    <Modal
      show={openModals["deleteSSID"]}
      onHide={() => toggle("deleteSSID")}
      centered
    >
      <Modal.Header closeButton>
        <b>Admin Function:</b>&nbsp; Delete SSID
      </Modal.Header>
      <Modal.Body className="m-3">
        <p className="text-left my-0">
          This will permanently delete the
          <b> {passedData?.ssid}</b> SSID from these settings. <br />
          <br />
          If you wish to proceed please confirm by clicking below:
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => toggle("deleteSSID")}>
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            toggle("deleteSSID");
            deleteSSID(passedData);
            setTimeout(() => dispatch(setRefreshData()), 2000);
          }}
        >
          Delete SSID
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSSIDModal;
