import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

import { Col, Container, Row, Breadcrumb, Tab } from "react-bootstrap";

import Line from "./Line";
import BuildingNetworkInfo from "./BuildingNetworkInfo";
import DeviceSelector from "./DeviceSelector";
import InventorySelector from "./InventorySelector";
import DisplayDeviceStats from "./DisplayDeviceStats";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import LongDataLoader from "../../components/LongDataLoader";
import { setCoreMonitoringData } from "../../redux/slices/coremonitoring";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";

const ViewBuildingNetwork = () => {
  const { bid } = useParams();
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const coreMonitoringData = useSelector((state) => state.coremonitoring);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(true);
  const [buildingData, setBuildingData] = useState([]);
  const [dataSource, setDataSource] = useState("");
  React.useEffect(() => {
    const fetchData = async () => {
      const axiosConfig = {
        headers: { Authorization: bearerToken },
      };

      if (isAuthenticated) {
        let coreMonitoringApiUrl =
          apiConfig.monitoringApiUrl + "core/inventory/" + bid;
        let buildingApiUrl = apiConfig.connectedApiUrl + "buildings/" + bid;

        try {
          let coreMonitoringResponse;
          try {
            coreMonitoringResponse = await axios.get(
              coreMonitoringApiUrl,
              axiosConfig
            );
          } catch (error) {
            if (error.response && error.response.status === 404) {
              const coreMonitoringResponseLegacy = await axios.get(
                apiConfig.monitoringApiUrl + "core/" + bid,
                axiosConfig
              );
              coreMonitoringResponse = coreMonitoringResponseLegacy;
            } else {
              throw error;
            }
          }

          const buildingResponse = await axios.get(buildingApiUrl, axiosConfig);

          if (
            coreMonitoringResponse.status === 200 &&
            buildingResponse.status === 200
          ) {
            dispatch(setCoreMonitoringData(coreMonitoringResponse.data));
            setBuildingData(buildingResponse.data.building);
            setNoData(false);
            setLoading(false);
            setDataSource(
              coreMonitoringResponse.config.url.includes("inventory")
                ? "inventory"
                : "legacy"
            );
          } else {
            setLoading(false);
            setNoData(true);
          }
        } catch (err) {
          setLoading(false);
          setNoData(true);
        }
      }
    };

    fetchData();
  }, [bearerToken, dispatch, bid, isAuthenticated]);

  const [selectedDevice, setSelectedDevice] = React.useState({
    deviceName: null,
    deviceInventoryId: null,
    inventorySource: null,
  });

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:monitoring" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Network Monitoring" />
        {loading && (
          <>
            {/* <LogoLoader className="d-block m-auto p-3" /> */}
            <LongDataLoader textSelector={"monitoring"} />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <Container fluid className="p-0">
            <Breadcrumb className="float-end mt-2">
              <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate("/monitoring/selectbuilding")}
              >
                Network Monitoring
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {buildingData.building_name}
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="h3 mb-3">
              Network Monitoring &nbsp;{buildingData.building_name}
              <span className="text-muted"></span>
            </h1>
            <div className="clearfix"></div>
            <Row>
              {/* <BuildingNetworkStats data={coreMonitoringData.core_devices} /> */}
            </Row>
            <Row>
              <Col lg="4" xl="4">
                <Row>
                  <Col>
                    <BuildingNetworkInfo
                      data={coreMonitoringData.core_details}
                      building_name={buildingData.building_name}
                      data_source={dataSource}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {dataSource === "inventory" ? (
                      <InventorySelector
                        data={{
                          monitoring: coreMonitoringData.core_devices,
                        }}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={setSelectedDevice}
                      />
                    ) : (
                      <DeviceSelector
                        data={coreMonitoringData.core_devices}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={setSelectedDevice}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg="8" xl="8" className="">
                <Row>
                  <Col>
                    <Line data={coreMonitoringData.bandwidth_usage_graph} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DisplayDeviceStats
                      data={coreMonitoringData.core_devices}
                      selectedDevice={selectedDevice}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>{/* <NetworkDiagram /> */}</Col>
                </Row>
              </Col>
            </Row>
            <Tab.Container id="select-device-tabs" defaultActiveKey="#1">
              <Row>
                <Col lg="9" xl="8" className=""></Col>
              </Row>
            </Tab.Container>
          </Container>
        )}
      </React.Fragment>
    </IsAllowed>
  );
};

export default ViewBuildingNetwork;
