import React, { useState, useContext } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import NotyfContext from "../../../../contexts/NotyfContext";
import axios from "axios";
import { apiConfig } from "../../../../config";
import useAuth from "../../../../hooks/useAuth";

const Configure8021XCredentialModal = ({
  openModals,
  toggle,
  passedData,
  dispatch,
  setRefreshData,
}) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const clearDevices = (data) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.dot11ApiUrl + `users/user/${data.username}/macaddresses/`,
        null,
        axiosConfig
      )
      .then(function (response) {
        if (response.data.success === 1) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.error,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };
  const changeAccess = (data) => {
    let setUserLevel = {};
    if (data.userlevel === 0) {
      setUserLevel = { userlevel: 1 };
    } else {
      setUserLevel = { userlevel: 0 };
    }
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.dot11ApiUrl + `users/user/${data.username}/userlevel`,
        setUserLevel,
        axiosConfig
      )
      .then(function (response) {
        if (response.data.success === 1) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.error,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };
  return (
    <Modal
      show={openModals["configureDot11Credential"]}
      onHide={() => toggle("configureDot11Credential")}
      centered
    >
      <Modal.Header closeButton>
        <b>Admin Function:</b>&nbsp; Configure Dot11 Credential
      </Modal.Header>
      <Modal.Body className="m-3">
        <p className="text-left my-0">
          User <b>{passedData.username}</b> currently has{" "}
          <Badge className="btn-gradient">
            {passedData?.devicevendor === ""
              ? 0
              : passedData?.devicevendor?.split("#~!~#")?.length}
            /5
          </Badge>{" "}
          devices connected.
          <br />
          <br />
          <Button
            className="w-100"
            onClick={() => {
              clearDevices(passedData);
              dispatch(setRefreshData());
              toggle("configureDot11Credential");
            }}
            disabled={passedData?.devicevendor === "" ? true : false}
          >
            Click here to clear devices
          </Button>
          <br />
          <br />
          User <b>{passedData.username}</b> currently has{" "}
          <Badge className="btn-gradient">
            {passedData.userlevel === 0 ? "Web" : "Web & LAN"}
          </Badge>{" "}
          access.
          <br />
          <br />
          {passedData.userlevel === 0 ? (
            <Button
              className="w-100"
              onClick={() => {
                changeAccess(passedData);
                setTimeout(() => dispatch(setRefreshData()), 2000);
                toggle("configureDot11Credential");
              }}
            >
              Click here to enable LAN access
            </Button>
          ) : (
            <Button
              className="w-100"
              onClick={() => {
                changeAccess(passedData);
                setTimeout(() => dispatch(setRefreshData()), 2000);
                toggle("configureDot11Credential");
              }}
            >
              Click here to disable LAN access
            </Button>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => toggle("configureDot11Credential")}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Configure8021XCredentialModal;
