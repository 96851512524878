import React, { useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import axios from "axios";
import useAxiosConfig from "../../hooks/useAxiosConfig";

import Loader from "../Loader";

const NewNumUsersDisplay = ({
  excellid,
  isLoading,
  setIsLoading,
  numAvailable,
  setNumAvailable,
}) => {
  const [numUsers, setNumUsers] = useState({});
  const [numInUse, setNumInUse] = useState(0);
  const [error, setError] = useState(null);
  const { baseURL: symbillBaseUrl, headers: symbillHeaders } =
    useAxiosConfig("symbillApiUrl");
  const { baseURL: dot11BaseUrl, headers: dot11Headers } =
    useAxiosConfig("credentialsApiUrl");

  useEffect(() => {
    const symbillAxiosInstance = axios.create({
      baseURL: symbillBaseUrl,
      headers: symbillHeaders,
    });
    const dot11AxiosInstance = axios.create({
      baseURL: dot11BaseUrl,
      headers: dot11Headers,
    });

    const safeApiCall = async (axiosInstance) => {
      try {
        const response = await axiosInstance;
        return { data: response.data };
      } catch (error) {
        return { error };
      }
    };

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const [symbillResponse, dot11Response] = await Promise.all([
          safeApiCall(
            symbillAxiosInstance.get(
              `newsymbillapi/numPaidWifiUsers/${excellid}`
            )
          ),
          safeApiCall(
            dot11AxiosInstance.get(`users/account_number/${excellid}`)
          ),
        ]);

        let newNumInUse = 0;
        let newNumAvailable = 0;

        if (dot11Response.error) {
          if (dot11Response.error.response?.status !== 404 || 204) {
            setError(dot11Response.error);
          }
        } else {
          newNumInUse =
            dot11Response.data &&
            dot11Response.data.users &&
            dot11Response.data.users.count
              ? dot11Response.data.users.count
              : 0;
        }

        if (symbillResponse.error) {
          if (symbillResponse.error.response?.status !== 404) {
            setError(symbillResponse.error);
          }
          setNumUsers({ inclusiveUsers: 0, paidUsers: 0 });
        } else {
          const paidUsers = Number(symbillResponse.data.paidUsers) || 0;
          const inclusiveUsers =
            Number(symbillResponse.data.inclusiveUsers) || 0;

          newNumAvailable = paidUsers + inclusiveUsers - newNumInUse;

          setNumUsers({ inclusiveUsers, paidUsers });
        }

        setNumInUse(newNumInUse);
        setNumAvailable(newNumAvailable);
      } catch (e) {
        console.error("Unexpected error:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    excellid,
    symbillBaseUrl,
    symbillHeaders,
    dot11BaseUrl,
    dot11Headers,
    setIsLoading,
    setNumAvailable,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Alert className="p-2">Error: {error.message}</Alert>;
  }

  return (
    <>
      <div>
        <span className="me-2">
          Wi-Fi users for <b>{excellid}</b>:
        </span>
        <Button variant="outline-dark" className="me-2 text-sm" disabled>
          Free: <strong className="text-m">{numUsers.inclusiveUsers}</strong>
        </Button>
        <Button variant="outline-dark" className="me-2 text-sm" disabled>
          Paid:<strong className="text-m">{numUsers.paidUsers}</strong>
        </Button>
        <Button variant="outline-dark" className="me-2 text-sm" disabled>
          Used: <strong className="text-m">{numInUse}</strong>
        </Button>
        <Button
          variant={numAvailable > 0 ? "outline-success" : "outline-danger"}
          className="me-2 text-sm"
          disabled
        >
          Avail: <b>{numAvailable}</b>
        </Button>
      </div>
    </>
  );
};

export default NewNumUsersDisplay;
