import { Message } from "rsuite";

const renderErrorMessages = (error) => {
  if (typeof error === "object" && error !== null) {
    return Object.keys(error).map((key, index) => (
      <Message key={index} type="warning" bordered>
        {error[key]}
      </Message>
    ));
  }
  return (
    <Message type="warning" bordered>
      {error}
    </Message>
  );
};

export { renderErrorMessages };
