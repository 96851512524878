import React, { useState } from "react";
import { Link } from "react-router-dom";
import { apiConfig } from "../../../config";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";

import {
  Card,
  Button,
  Dropdown,
  ButtonGroup,
  DropdownButton,
  Badge,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faKey, faTrash } from "@fortawesome/free-solid-svg-icons";

import IsAllowed from "../../../components/IsAllowed";
import NotFound from "../../../components/NotFound";

import useAuth from "../../../hooks/useAuth";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "../../../redux/slices/refreshData";
import ColumnFilteringTable from "../../../components/tables/ColumnFilteringTable";
import AddNew8021XCredentialModal from "./modals/NewAddNew8021XCredentialModal";
import PasswordResetModal from "./modals/NewPasswordResetModal";

import Configure8021XCredentialModal from "./modals/NewConfigure8021XCredentialModal";
import Delete8021XCredentialModal from "./modals/NewDelete8021XCredentialModal";
import PurchaseAdditionalUsersModal from "./modals/PurchaseAdditionalUsersModal";
// import ConfigureDPSKModal from "./modals/ConfigureDPSKModal";
// import AddNewDPSKModal from "./modals/AddNewDPSKModal";
// import DPSKResetModal from "./modals/DPSKResetModal";
// import DeleteDPSKModal from "./modals/DeleteDPSKModal";

import usePermissions from "../../../hooks/usePermissions";

const NewCredentialsList = () => {
  const dot11UsersTableColumns = [
    {
      Header: "Account Number(s)",
      accessor: "wavenetIdList",
      Cell: (e) => (
        <div>
          {Array.isArray(e.value) ? (
            e.value.map((account, index) => (
              <div key={index} style={{ marginBottom: "5px" }}>
                <Link to={account ? `/customers/profile/${account}` : "#"}>
                  <Button
                    variant={account ? "outline-primary" : "outline-secondary"}
                  >
                    {account ? account : "----------"}
                  </Button>
                </Link>
              </div>
            ))
          ) : (
            <Link to={e.value ? `/customers/profile/${e.value}` : "#"}>
              <Button
                variant={e.value ? "outline-primary" : "outline-secondary"}
              >
                {e.value ? e.value : "----------"}
              </Button>
            </Link>
          )}
        </div>
      ),
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "# Devices",
      accessor: "devicevendor",
      disableFilters: true,
      Cell: ({ row }) => {
        let splitItems = row.original.devicevendor.split("#~!~#");
        let hasItems =
          splitItems.length > 1 ||
          (splitItems.length === 1 && splitItems[0] !== "");
        let deviceList = splitItems
          .map((item, index) => `#${index + 1}: ${item}`)
          .join(", ");
        if (hasItems) {
          return (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Devices: {deviceList}</Tooltip>}
            >
              <Badge className="btn-gradient">
                {row.original.credentialtype !== "DPSK"
                  ? row.original.devicevendor === ""
                    ? "0/5"
                    : splitItems.length + "/5"
                  : "N/A"}
              </Badge>
            </OverlayTrigger>
          );
        } else {
          return <Badge className="btn-gradient">{"0/5"}</Badge>;
        }
      },
    },
    {
      Header: "Credential Type",
      accessor: "credentialtype",
      disableFilters: true,
      Cell: (e) => <Badge className="btn-gradient">{e.value}</Badge>,
    },
    {
      Header: "SSID(s)",
      accessor: "customerssids",
      disableFilters: true,
      Cell: (e) => {
        if (
          e.row.original.customerssids &&
          e.row.original.customerssids.length > 0
        ) {
          return e.row.original.customerssids.map((ssid) => (
            <Badge className="btn-gradient">{ssid.ssid}</Badge>
          ));
        } else {
          return <Badge className="btn-gradient">{"--"}</Badge>;
        }
      },
    },
    {
      Header: "Access Level",
      accessor: "userlevel",
      disableFilters: true,
      disableSortBy: true,
      Cell: (e) => {
        let vlanList =
          e.row.original.uservlans.length > 0
            ? e.row.original.uservlans
                .map((item) => `VLAN ${item.vlan} @ ${item.wifiid}`)
                .join(", <br/>")
            : `Guest-Wifi`;
        return (
          <OverlayTrigger
            placemment="right"
            overlay={<Tooltip>{vlanList}</Tooltip>}
          >
            <span>{e.value === 0 ? "Web" : "Web & LAN"}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      Header: "ZoneGuid",
      accessor: "dpsk_zone_guid",
    },
    {
      Header: "ZoneGuid",
      accessor: "dpsk_wlan_guid",
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <>
          {row.original.credentialtype !== "DPSK" ? (
            <FontAwesomeIcon
              icon={faKey}
              className="ms-2"
              onClick={() => {
                toggle("sendPasswordReset");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            ""
          )}
          <IsAllowed to="edit:credentials">
            {row.original.credentialtype !== "DPSK" ? (
              <FontAwesomeIcon
                icon={faCog}
                className="ms-2"
                onClick={() => {
                  toggle("configureDot11Credential");
                  passData(row.original);
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              ""
            )}
          </IsAllowed>
          <IsAllowed to="delete:credentials">
            <FontAwesomeIcon
              icon={faTrash}
              className="ms-2"
              onClick={() => {
                row.original.credentialtype !== "DPSK"
                  ? toggle("deleteDot11Credential")
                  : toggle("deleteDpskCredential");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
    },
  ];

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };
  const [passedData, setPassedData] = useState({});
  const passData = (data) => {
    setPassedData(data);
  };

  const { userAccessToken, isAuthenticated, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [credentialsList, setCredentialsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const selectedSearchFilter = useSelector((state) => state.searchfilters);
  const selectedAccount = useSelector((state) => state.accountswitcher);
  const refreshData = useSelector((state) => state.refreshdata);

  const [loggedInUser, setLoggedInUser] = useState(null);

  React.useEffect(() => {
    if (user && isAuthenticated) {
      setLoggedInUser(user.email);
    }
  }, [user, isAuthenticated]);

  let defaultSearchType;
  const defaultSearchTypeOrg = usePermissions("view:credentials:org");
  const defaultSearchTypeLandlord = usePermissions("view:credentials:landlord");
  const defaultSearchTypeAll = usePermissions("view:credentials:all");
  if (defaultSearchTypeOrg[1]) {
    defaultSearchType = "account_number";
  } else if (defaultSearchTypeLandlord[1] || defaultSearchTypeAll[1]) {
    defaultSearchType = "operator";
  }
  let defaultSearchValue = null;
  if (defaultSearchTypeOrg[1]) {
    defaultSearchValue = selectedAccount.current_account;
  } else if (defaultSearchTypeLandlord[1] || defaultSearchTypeAll[1]) {
    defaultSearchValue = selectedLandlord.landlordid;
  }

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let search_type = selectedSearchFilter.search_type || defaultSearchType;
    let search_value = selectedSearchFilter.search_value || defaultSearchValue;
    let dot11Api =
      apiConfig.credentialsApiUrl +
      `users/${search_type}/` +
      search_value +
      "/?includeVlans=true&fields=id,username,email,excellid,wavenetIdList,devicevendor,userlevel,credentialtype";
    if (search_type && search_value && isAuthenticated) {
      axios
        .get(dot11Api, axiosConfig)
        .then((response) => {
          if (
            response.data.users &&
            response.data.users.rows &&
            response.data.users.rows.length > 0
          ) {
            setCredentialsList(response.data.users.rows);
            setLoading(false);
            setNoData(false);
          } else {
            setCredentialsList([]);
            setLoading(false);
            setNoData(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setNoData(true);
        });
    }
  }, [
    refreshData,
    bearerToken,
    selectedLandlord.landlordid,
    selectedSearchFilter,
    defaultSearchType,
    defaultSearchValue,
    isAuthenticated,
  ]);

  const [customerOptions, setCustomerOptions] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    let getCustomersApi = null;
    let getCustomersApiIsArray = false;
    if (defaultSearchType === "operator") {
      getCustomersApi =
        apiConfig.symbillApiUrl +
        "newsymbillapi/byCtidList/" +
        selectedLandlord.landlordid;
      getCustomersApiIsArray = true;
    } else if (defaultSearchType === "account_number") {
      getCustomersApi =
        apiConfig.symbillApiUrl +
        "newsymbillapi/byAccountNumber/" +
        selectedAccount.current_account;
    }
    if (isAuthenticated) {
      axios
        .get(getCustomersApi, axiosConfig)
        .then((response) => {
          let customersList = [];
          if (getCustomersApiIsArray) {
            response.data.forEach((customer) => {
              customersList.push({
                value: customer.accountNumber,
                label:
                  customer.companyName + " (" + customer.accountNumber + ")",
              });
            });
          } else {
            customersList.push({
              value: response.data.accountNumber,
              label:
                response.data.companyName +
                " (" +
                response.data.accountNumber +
                ")",
            });
          }
          setCustomerOptions(customersList);
        })
        .catch((error) => {});
    }
  }, [
    bearerToken,
    selectedLandlord,
    defaultSearchType,
    selectedAccount,
    isAuthenticated,
  ]);

  const dispatch = useDispatch();
  return (
    <>
      <Card>
        <Card.Header>
          <IsAllowed to="create:credentials">
            <div className="card-actions float-end">
              {/*<Dropdown align="end">
                <Dropdown.Toggle as="a" bsPrefix="-">
                  <MoreHorizontal />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => toggle("addNewCredential")}>
                    Add New 802.1X Credential
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => toggle("wifiPurchase")}>
                    Request Additional 802.1X Users
                  </Dropdown.Item>
                   <Dropdown.Item onClick={() => toggle("addNewDpsk")}>
                    Add New DPSK
                  </Dropdown.Item> 
                </Dropdown.Menu>
              </Dropdown>*/}
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-variants-primary`}
                variant={`primary`}
                title="Manage "
                align="end"
              >
                <Dropdown.Item onClick={() => toggle("addNewCredential")}>
                  Add New Dot11 User
                </Dropdown.Item>
                <Dropdown.Item onClick={() => toggle("wifiPurchase")}>
                  Request Additional Dot11 Users
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </IsAllowed>
          <Card.Title className="mb-0">Wi-Fi Credentials List</Card.Title>
        </Card.Header>
        <Card.Body className="py-0">
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-4" />
            </>
          )}
          {!loading && noData && isAuthenticated && <NotFound />}
          {!loading && !noData && (
            <ColumnFilteringTable
              columns={dot11UsersTableColumns}
              data={credentialsList}
              actions
              hiddenColumns={[
                "dpsk_zone_guid",
                "dpsk_wlan_guid",
                selectedLandlord.landlordid === 2 ? "actions" : "", // Disable actions for Workspace, they manage users in their own portal
              ]}
            />
          )}
        </Card.Body>
      </Card>

      <AddNew8021XCredentialModal
        openModals={openModals}
        toggle={toggle}
        customerOptions={customerOptions}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />

      <PasswordResetModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
      />

      <Configure8021XCredentialModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />

      <Delete8021XCredentialModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />

      <PurchaseAdditionalUsersModal
        openModals={openModals}
        toggle={toggle}
        customerOptions={customerOptions}
        username={loggedInUser}
      />

      {/* <DPSKResetModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
      />

      <AddNewDPSKModal
        openModals={openModals}
        toggle={toggle}
        wlanOptions={wlanOptions}
        customerOptions={customerOptions}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />

      <ConfigureDPSKModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />

      <DeleteDPSKModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      /> */}
    </>
  );
};

export default NewCredentialsList;
