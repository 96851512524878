import { useState, useEffect, useRef } from "react";

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const useDynamicSelectOptions = (
  fields,
  userAccessToken,
  selectedLandlord,
  myFormData
) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const prevDependenciesRef = useRef({});

  useEffect(() => {
    fields.forEach((field) => {
      if (
        field.type === "dynamicSelect" ||
        field.type === "dynamicMultiSelect"
      ) {
        const dependencies = (field.queryHandlerDependencies || []).map((dep) =>
          getNestedValue(myFormData, dep)
        );
        const prevDependencies = prevDependenciesRef.current[field.name] || [];

        const dependenciesChanged = dependencies.some(
          (dep, index) => dep !== prevDependencies[index]
        );

        if (!options[field.name] || dependenciesChanged) {
          field.queryHandler(
            userAccessToken,
            setLoading,
            (newOptions) =>
              setOptions((prevOptions) => ({
                ...prevOptions,
                [field.name]: newOptions,
              })),
            selectedLandlord,
            myFormData
          );
        }

        prevDependenciesRef.current[field.name] = dependencies;
      }
    });
  }, [
    fields,
    options,
    userAccessToken,
    selectedLandlord,
    myFormData,
    // eslint-disable-next-line
    ...fields.flatMap((field) => field.queryHandlerDependencies || []),
    // eslint-disable-next-line
    ...fields.flatMap((field) =>
      (field.queryHandlerDependencies || []).map((dep) =>
        getNestedValue(myFormData, dep)
      )
    ),
  ]);

  return { loading, options };
};

const useExternalQuery = (
  stepConfig,
  myFormData,
  userAccessToken,
  setMyFormData,
  selectedLandlord
) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");

  const myFormDataRef = useRef(myFormData);

  useEffect(() => {
    myFormDataRef.current = myFormData;
  }, [myFormData]);

  useEffect(() => {
    if (stepConfig.queryHandler) {
      if (stepConfig.stepType === "externalQuery__hubspot") {
        stepConfig.queryHandler(
          myFormDataRef.current.company_name,
          myFormDataRef.current.company_domain,
          myFormDataRef.current.company_reg_number,
          userAccessToken,
          setLoading,
          setResults
        );
      } else if (stepConfig.stepType === "externalQuery__hubspot_order") {
        stepConfig.queryHandler(
          myFormDataRef.current,
          userAccessToken,
          setLoading,
          setResults
        );
      } else if (stepConfig.stepType === "externalQuery__pricing") {
        stepConfig.queryHandler(
          myFormDataRef.current,
          userAccessToken,
          setLoading,
          setResults,
          setError,
          setMyFormData
        );
      }
    }
  }, [stepConfig, userAccessToken, setMyFormData]);

  return { loading, results, error };
};

export { useDynamicSelectOptions, useExternalQuery };
