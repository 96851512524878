import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NewDot11ResetPassword from "../../components/auth/NewDot11ResetPassword";
import axios from "axios";
import { apiConfig } from "../../config";

const NewResetPasswordPage = () => {
  const { randomid } = useParams();

  const [state, setState] = useState({
    loading: true,
    error: false,
    errorMessage: randomid ? "" : "No token provided.",
    pwResetTokenIsValid: false,
    isNewCustomer: false,
  });

  useEffect(() => {
    if (!randomid) return;

    axios
      .get(`${apiConfig.credentialsApiUrl}public/validate/${randomid}`)
      .then((response) => {
        if (response.status === 200) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            pwResetTokenIsValid: true,
            isNewCustomer: response.data.message.isNewCustomer,
          }));
        }
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: true,
          errorMessage: error.response.data.message || "No token provided.",
        }));
      });
  }, [randomid]);

  const { loading, error, errorMessage, pwResetTokenIsValid, isNewCustomer } =
    state;

  const titleMessage = isNewCustomer
    ? "Verify your wi-fi account"
    : "Reset your wi-fi password";
  const leadMessage = "Set up your new password below.";

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      <div className="text-center mt-4">
        {!loading && (
          <>
            <h1 className="h2">{titleMessage}</h1>
            <p className="lead">{leadMessage}</p>
          </>
        )}
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            {error && errorMessage && (
              <div className="alert alert-danger p-2" role="alert">
                {errorMessage}
              </div>
            )}
            {!loading && pwResetTokenIsValid && (
              <NewDot11ResetPassword
                token={randomid}
                isNewCustomer={isNewCustomer}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default NewResetPasswordPage;
