import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const { apiConfig } = require("../config");
const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ isInMaintenanceMode: 1 });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.usersApiUrl}publicSettings`
        );
        setSettings(response.data);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
