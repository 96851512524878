import React, { useState, useContext } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import { apiConfig } from "../../config";
import TurndownService from "turndown";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NotyfContext from "../../contexts/NotyfContext";
import PermissionsContext from "../../contexts/PermissionsContext";
import { useSelector } from "react-redux";
import { ReactComponent as Loader } from "../../assets/img/wavenet-animated-loader3.svg";

const AddTicketForm = () => {
  const { user, userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const { isAllowedTo } = useContext(PermissionsContext);
  const notyf = useContext(NotyfContext);

  const [ticketBody, setTicketBody] = useState("");
  const [ticketSubject, setTicketSubject] = useState("");
  const [onBehalfOf, setOnBehalfOf] = useState(0);
  const [companySelected, setCompanySelected] = useState("");
  const [contactSelected, setContactSelected] = useState("");
  const [buildingSelected, setBuildingSelected] = useState("");
  const notificationConfig = useState({
    type: "success",
    duration: "30000",
    ripple: true,
    dismissible: true,
    positionX: "right",
    positionY: "top",
  });

  const { type, duration, ripple, dismissible, positionX, positionY } =
    notificationConfig;

  const [buildingOptions, setBuildingOptions] = useState([]);
  const [employerOptions, setEmployerOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const selectedAccount = useSelector((state) => state.accountswitcher);

  const handleCompanySelected = (e) => {
    setCompanySelected(e);
    setContactSelected("");
  };

  const handleTicketBody = (e) => {
    setTicketBody(e);
  };

  const handleTicketSubject = (e) => {
    setTicketSubject(e.target.value);
  };

  const handleOnBehalfOf = (onBehalfOf) => {
    setOnBehalfOf(onBehalfOf);
  };

  const handleContactSelected = (e) => {
    setContactSelected(e);
  };

  const handleBuildingSelected = (e) => {
    setBuildingSelected(e);
  };

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    if (!isAuthenticated) return;

    const fetchBuildings = async () => {
      const response = await axios.get(
        `${apiConfig.connectedApiUrl}buildings/withoperator?page_size=1000&building_operator=${selectedLandlord.landlordid}`,
        axiosConfig
      );

      return response.data.buildings.items.map((building) => ({
        value: `${building.building_name} (${building.operator.operator_name}) (${building.id})`,
        label: building.building_name,
      }));
    };

    const fetchCustomers = async () => {
      const onlyOwnOrg =
        (await isAllowedTo("create:tickets:onbehalf:org")) ||
        (await isAllowedTo("create:tickets:onbehalf:own"));

      const url = onlyOwnOrg
        ? `${apiConfig.symbillApiUrl}newsymbillapi/byAccountNumber/${selectedAccount.current_account}`
        : `${apiConfig.symbillApiUrl}newsymbillapi/byCtidList/${selectedLandlord.landlordid}`;

      const wavenetUser = user?.landlordid === 42 ? true : false;
      const response = await axios.get(url, axiosConfig);
      const data = Array.isArray(response.data)
        ? response.data
        : [response.data];

      let customerList = data.map((customer) => ({
        value: customer.accountNumber,
        label: `${customer.companyName} (${customer.accountNumber})`,
      }));
      if (wavenetUser) {
        customerList.push({
          value: "XLW005803",
          label: "Wavenet Connected (Internal Accounts) (XLW005803)",
        });
      }
      return customerList;
    };

    const fetchUsers = async () => {
      const response = await axios.get(
        `${apiConfig.usersApiUrl}users`,
        axiosConfig
      );

      if (Array.isArray(response.data)) {
        return response.data.map((user) => ({
          value: user.email,
          label: user?.name,
          account: user.app_metadata.accountnumber,
        }));
      }
      return [];
    };

    Promise.all([fetchBuildings(), fetchCustomers(), fetchUsers()])
      .then(([buildings, customers, users]) => {
        setBuildingOptions(buildings);
        setEmployerOptions(customers);
        setContactOptions(users);
        setOptionsLoading(false);
      })
      .catch((err) => {
        setOptionsLoading(false);
      });
  }, [
    bearerToken,
    selectedLandlord,
    isAuthenticated,
    isAllowedTo,
    selectedAccount,
    user,
  ]);

  const navigate = useNavigate();

  const self_company = employerOptions.find(
    (employer) => employer.value === selectedAccount.current_account
  );

  const self = {
    contact: {
      label: user.displayName,
      value: user.email,
    },
    company: {
      label: self_company?.label,
      value: selectedAccount.current_account,
    },
  };

  const handleCreateTicketSubmit = (event) => {
    event.preventDefault();

    const createTicketBody = {
      company: companySelected,
      contact: contactSelected,
      subject: ticketSubject,
      building: buildingSelected.value,
      landlord: `${selectedLandlord.landlordname} (${selectedLandlord.landlordid})`,
      body: new TurndownService({ emDelimiter: "*" }).turndown(ticketBody),
    };

    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    axios
      .post(`${apiConfig.ticketsApiUrl}tickets/`, createTicketBody, axiosConfig)
      .then((response) => {
        notyf.open({
          type,
          message: `Your ticket was created successfully with ID: ${response.data.id}`,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });

        setTimeout(() => navigate(`/tickets/view/${response.data.id}`), 200);
      })
      .catch((error) => {
        notyf.open({
          type: "warning",
          message: `There was an error raising your ticket. ${error.response.data.message}`,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.error("Error creating ticket:", error.response.data.message);
      });
  };

  return (
    <React.Fragment>
      {optionsLoading ? (
        <Loader className="d-block m-auto p-4" />
      ) : (
        <Form className="row" onSubmit={handleCreateTicketSubmit}>
          <Col xl="8">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">1 Select a contact</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  Choose the contact you want to create this ticket on behalf
                  of. You can create a ticket requested by yourself or on behalf
                  of someone else.
                </p>
                <Form.Group className="m-2">
                  <Form.Check
                    label="Create ticket as yourself"
                    type="radio"
                    name="onbehalfof"
                    checked={onBehalfOf === 1}
                    onChange={(e) => {
                      handleOnBehalfOf(1);
                      handleCompanySelected(self.company);
                      handleContactSelected(self.contact);
                    }}
                  />
                </Form.Group>
                <IsAllowed to="create:tickets:onbehalf">
                  <Form.Group className="m-2">
                    <Form.Check
                      label="Create ticket on behalf of someone else"
                      type="radio"
                      name="onbehalfof"
                      checked={onBehalfOf === 2}
                      onChange={(e) => handleOnBehalfOf(2)}
                    />
                  </Form.Group>
                </IsAllowed>
                {onBehalfOf === 1 ? (
                  <div>
                    <Row className="p-2">
                      <Col xl="6">
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="company">Company</Form.Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={employerOptions}
                            id="company"
                            defaultValue={self.company}
                            value={self.company}
                            isDisabled={1}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl="6">
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="contact">Contact</Form.Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={self.contact}
                            value={self.contact}
                            isDisabled={1}
                            id="contact"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                ) : onBehalfOf === 2 ? (
                  <div>
                    <Row className="p-2">
                      <Col xl="6">
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="company">Company</Form.Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={employerOptions}
                            id="company"
                            defaultValue={employerOptions[1]}
                            value={companySelected}
                            onChange={handleCompanySelected}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl="6">
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="contact">Contact</Form.Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={contactOptions.filter((c) => {
                              return c.account.includes(companySelected.value);
                            })}
                            value={contactSelected}
                            onChange={handleContactSelected}
                            id="contact"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">2 Ticket building</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>Select the building that this ticket relates to.</p>
                <Form.Group className="mb-3">
                  <Form.Label>Building</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={buildingOptions}
                    value={buildingSelected}
                    onChange={handleBuildingSelected}
                    id="building"
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">3 Ticket subject</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  Write a descriptive subject line so that the support team can
                  easily understand what the ticket is in relation to.
                </p>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Ticket Subject"
                  className="mb-3"
                >
                  <Form.Control
                    type="input"
                    placeholder={ticketSubject}
                    size="lg"
                    onChange={handleTicketSubject}
                  />
                </FloatingLabel>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">4 Ticket details</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  Write the body of your ticket below. Be sure to include any
                  and all information which can help the support team reach a
                  resolution quickly.
                </p>
                <ReactQuill
                  id="ticketbody"
                  placeholder={ticketBody}
                  onChange={handleTicketBody}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="sticky-top" style={{ top: "1em" }}>
              <Card.Header>
                <Card.Title className="mb-0">Summary</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  The details for your new building will be confirmed below.
                  Check everything is correct before creating the building.
                </p>
                {onBehalfOf === 1 ? (
                  <div>
                    <h4>Creating ticket as yourself</h4>
                    <span className="text-lg">
                      {contactSelected.label} @ {companySelected.label}
                    </span>

                    <hr />
                  </div>
                ) : (
                  ""
                )}
                {onBehalfOf === 2 && contactSelected !== "" ? (
                  <div>
                    <h4>Creating ticket on behalf of someone else</h4>
                    <span className="text-lg">
                      {contactSelected.label} @ {companySelected.label}
                    </span>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                {buildingSelected !== "" ? (
                  <div>
                    <h4>Ticket building</h4>
                    <span className="text-lg">{buildingSelected.label}</span>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                {ticketSubject !== "" ? (
                  <div>
                    <h4>Ticket subject</h4>
                    <span className="text-lg">{ticketSubject}</span>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                {ticketBody !== "" ? (
                  <div>
                    <h4>Ticket details</h4>
                    <span className="text-lg">
                      You've typed a ticket body. We can't display it here
                      because it is too long, but be sure to check it's complete
                      and correct.
                    </span>
                    <hr />
                  </div>
                ) : (
                  ""
                )}

                {ticketSubject !== "" &&
                ticketBody !== "" &&
                onBehalfOf === 2 &&
                contactSelected !== "" ? (
                  <div>
                    <Link to="/tickets/list">
                      <Button className="m-2" variant="light">
                        Cancel
                      </Button>
                    </Link>
                    <Button type="submit" className="m-2 w-75 float-end">
                      Create Ticket
                    </Button>
                  </div>
                ) : ticketSubject !== "" &&
                  ticketBody !== "" &&
                  onBehalfOf === 1 ? (
                  <div>
                    <Link to="/tickets/list">
                      <Button className="m-2" variant="light">
                        Cancel
                      </Button>
                    </Link>
                    <Button type="submit" className="m-2 w-75 float-end">
                      Create Ticket
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Link to="/tickets/list">
                      <Button className="m-2" variant="light">
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      type="submit"
                      className="m-2 w-75 float-end"
                      disabled
                    >
                      Create Ticket
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Form>
      )}
    </React.Fragment>
  );
};

const NewTicket = () => {
  return (
    <IsAllowed to="create:tickets" fallback={<NotAuthorised />}>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Create New Ticket</h1>
        <Row className="row-fill">
          <AddTicketForm />
        </Row>
      </Container>
    </IsAllowed>
  );
};

export default NewTicket;
