import React from "react";
import Chart from "react-apexcharts";

import usePalette from "../../hooks/usePalette";

const NetworkAvailabilityLine = ({ data, categories }) => {
  const palette = usePalette();

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: "stepline",
      dashArray: [0, 8, 5],
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 99.7,
      max: 100.1,
      show: false,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (%)";
            },
          },
          formatter: function (e) {
            return e.toFixed(2);
          },
        },
      ],
      followCursor: true,
    },
    colors: [
      palette.connectedorange,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <div className="chart-xxs">
      <Chart options={options} series={data} type="line" height="80" />
    </div>
  );
};

export default NetworkAvailabilityLine;
