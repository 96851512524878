import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import { apiConfig } from "../../config";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";
import { tableColumns } from "./customerTicketsData.js";
import { tenanciesTableColumns } from "./customerTenanciesData.js";

import NotyfContext from "../../contexts/NotyfContext";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import NoData from "../../components/NoData";
import ColumnSortingTable from "../../components/tables/ColumnSortingTable";
import GetSupport from "../../components/GetSupport";
import KeyContactsList from "../../components/CompanyKeyContacts";

import {
  Badge,
  Card,
  Col,
  Container,
  Row,
  Dropdown,
  Table,
  Breadcrumb,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import {
  MoreHorizontal,
  Download,
  Upload,
  LifeBuoy,
  Phone,
  Globe,
  Mail,
  Loader,
} from "react-feather";

import useAuth from "../../hooks/useAuth";

const initial = (v) => {
  let words = v.split(" ").filter((word) => word !== "");
  let result = "";

  for (let i = 0; i < words.length && result.length < 2; i++) {
    const char = words[i].charAt(0).toLowerCase();
    if (/[a-z0-9]/.test(char)) {
      result += char;
    }
  }

  return result;
};

const ProfileDetails = ({
  name,
  accountnumber,
  domain,
  email,
  phone,
  description,
}) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        {name} &nbsp;
        <span className="text-muted text-sm">{accountnumber}</span>
      </Card.Title>
    </Card.Header>
    <Card.Body>
      {name ? (
        <Row className="g-0">
          <Col sm="3" xl="3" className="col-xxl-2 text-center">
            <img
              src={"https://logo.clearbit.com/" + domain}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  "https://i1.wp.com/cdn.auth0.com/avatars/" +
                  initial(name) +
                  ".png?ssl=1";
              }}
              alt="Stacie Hall"
              className="img-fluid rounded-circle mb-2"
              width="96"
              height="96"
            />
          </Col>
          <Col sm="9" xl="9" className="col-xxl-10">
            <div className="text-muted mb-2 min-height-30">{description}</div>
            <Row className="pt-3 text-center">
              <Col md="4">
                <Phone size={14} className="me-2" />
                {phone}
              </Col>
              <Col md="4">
                <Mail size={14} className="me-2" />
                {email}
              </Col>
              <Col md="4">
                <Globe size={14} className="me-2" />
                {domain}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <NoData noDataMessage="Could not find profile details for this account." />
      )}
    </Card.Body>
  </Card>
);

const CustomerTenanciesTable = ({ accountnumber }) => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken, isAuthenticated } = useAuth();
  const [tenancyData, setTenancyData] = useState([
    {
      id: 12165,
      tenant_id: "TA709271",
      room_id: 8333,
      start_date: "2019-04-15",
      end_date: "2020-02-26",
      start_source: "Frances.Taylor",
      end_source: "Tyna.Kedzierska",
      building_id: 9,
    },
  ]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    let isMounted = true;
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      let tenanciesApi =
        apiConfig.connectedApiUrl +
        "tenancies?page_size=100&tenant_id=" +
        accountnumber;
      let roomsApi = apiConfig.connectedApiUrl + "rooms?page_size=100000";
      let buildingsApi =
        apiConfig.connectedApiUrl +
        "buildings?page_size=1000&building_operator=" +
        selectedLandlord.landlordid;
      const tenanciesRequest = axios.get(tenanciesApi, axiosConfig);
      const roomsRequest = axios.get(roomsApi, axiosConfig);
      const buildingsRequest = axios.get(buildingsApi, axiosConfig);
      axios
        .all([tenanciesRequest, roomsRequest, buildingsRequest])
        .then(
          axios.spread((...responses) => {
            const tenanciesResponse = responses[0];
            const roomsResponse = responses[1];
            const buildingsResponse = responses[2];
            let tenanciesList = [];
            if (tenanciesResponse.data && tenanciesResponse.data.tenancies) {
              tenanciesResponse.data.tenancies.items.forEach((tenancy) => {
                let roomMatch = roomsResponse.data.rooms.items.filter(
                  (room) => {
                    return tenancy.room_id === room?.id;
                  }
                );
                tenanciesList.push({
                  tenancystart: tenancy.start_date || null,
                  tenancyend: tenancy.end_date || null,
                  unitsize: roomMatch[0].area_squarefeet || null,
                  unit: roomMatch[0].room_number || null,
                  room_id: roomMatch[0].id || null,
                  floor: null,
                  building: null,
                  building_id: roomMatch[0].building_id,
                });
              });
            }

            let newTenanciesList = [];
            tenanciesList.forEach((tenancy) => {
              let buildingMatch = buildingsResponse.data.buildings.items.filter(
                (building) => {
                  return tenancy.building_id === building?.id;
                }
              );
              tenancy["building_name"] = buildingMatch[0].building_name;
              newTenanciesList.push({
                tenancy,
              });
            });
            if (isMounted) {
              setLoading(false);
              setTenancyData(newTenanciesList);
            }
          })
        )
        .catch(function (error) {
          setLoading(false);
          setTenancyData([]);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [bearerToken, accountnumber, selectedLandlord, isAuthenticated]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">Tenancies</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && (
          <>
            <LogoLoader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && (
          <ColumnSortingTable
            columns={tenanciesTableColumns}
            data={tenancyData}
            hiddenColumns={[
              "tenancy.building_id",
              "accountnumber",
              "pdf_available",
            ]}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const CustomerTicketsTable = ({ accountnumber }) => {
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [ticketsList, setTicketsList] = useState([]);
  const refreshData = useSelector((state) => state.refreshdata);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      let axiosUrl =
        apiConfig.ticketsApiUrl + "tickets/list?accountnumber=" + accountnumber;
      axios
        .get(axiosUrl, axiosConfig)
        .then(function (response) {
          if (!response.data) {
            throw new Error("No data from backend");
          }
          setLoading(false);
          setTicketsList(response?.data);
        })
        .catch(function (error) {
          setLoading(false);
          setNoData(true);
        });
    }
  }, [refreshData, bearerToken, accountnumber, isAuthenticated]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">Support Tickets</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && (
          <>
            <LogoLoader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnSortingTable
            columns={tableColumns}
            data={ticketsList}
            hiddenColumns={[
              "tenancy.building_id",
              "accountnumber",
              "pdf_available",
            ]}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const CustomerInvoicesTable = ({ accountnumber }) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");
  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }
  const { userAccessToken, isAuthenticated } = useAuth();
  const [invoicesData, setInvoicesData] = useState([]);
  const dispatch = useDispatch();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const downloadInvoice = async (accountnumber, invoiceperiod) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.symbillApiUrl +
          "billing/invoices/" +
          accountnumber +
          "/" +
          invoiceperiod,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          notyf.open({
            type,
            message: "Invoice has been downloaded",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.response.data,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
        let pdfArr = base64ToArrayBuffer(response.data.FileBytes);
        saveByteArray(response.data.Title + ".pdf", pdfArr);
      });
  };
  const invoicesTableColumns = [
    {
      Header: "Invoice #",
      accessor: "invoiceref",
      Cell: (e) => (
        <div className="text-center">
          {e.row.original.pdf_available !== 0 ? (
            <Link
              to="#"
              onClick={(c) => {
                downloadInvoice(
                  e.row.original.accountnumber,
                  e.row.original.invoiceperiod
                );
                notyf.open({
                  type: "default",
                  message: "Download has been requested...",
                  duration,
                  ripple,
                  dismissible,
                  position: {
                    x: positionX,
                    y: positionY,
                  },
                });
              }}
            >
              <Button variant="outline-primary">{e.value}</Button>
            </Link>
          ) : (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-right">Not Available For Download</Tooltip>
              }
            >
              <Button variant="outline-secondary">{e.value}</Button>
            </OverlayTrigger>
          )}
        </div>
      ),
    },
    {
      Header: "Account Number",
      accessor: "accountnumber",
      isVisible: false,
    },
    {
      Header: "Invoice Period",
      accessor: "invoiceperiod",
    },
    {
      Header: "PDF Available",
      accessor: "pdf_available",
    },
    {
      Header: "Invoice Date",
      accessor: "invoicedate",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY"),
    },
    {
      Header: "Invoice Total",
      accessor: "invoicetotal",
      Cell: (e) => "£" + e.value.toFixed(2),
    },
  ];
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      axios
        .get(
          apiConfig.symbillApiUrl +
            "newsymbillapi/invoicesByAccountNumber/" +
            accountnumber,
          axiosConfig
        )
        .then(function (response) {
          let invoicesList = [];
          response.data.forEach((invoice) => {
            invoicesList.push({
              invoiceref: invoice.invoiceNumber,
              customer: invoice.companyName,
              invoiceperiod: invoice.invoicePeriod,
              invoicedate: invoice.invoiceDate,
              invoicetotal: invoice.invoiceValue,
              accountnumber: invoice.accountNumber,
              pdf_available: invoice.pdfAvailable,
            });
          });
          setLoading(false);
          setInvoicesData(invoicesList);
        })
        .catch(function (error) {
          setLoading(false);
          setNoData(true);
        });
    }
  }, [bearerToken, accountnumber, dispatch, isAuthenticated]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">Invoices</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && (
          <>
            <LogoLoader className="d-block m-auto p-4" />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnSortingTable
            columns={invoicesTableColumns}
            data={invoicesData}
            hiddenColumns={["pdf_available"]}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const KeyContacts = ({ data, loading }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Key Contacts</Card.Title>
      </Card.Header>
      <Card.Body>
        {data.key_contacts && !loading ? (
          <KeyContactsList data={data} />
        ) : (
          <NoData noDataMessage="Could not find any key contacts for this account." />
        )}
      </Card.Body>
    </Card>
  );
};

const AccountManager = ({ data, loading }) => {
  const [accountManager, setAccountManager] = useState();
  React.useEffect(() => {
    if (data.key_contacts) {
      let account_manager = data?.key_contacts.filter((contact) => {
        return contact.type === "Account Manager";
      });
      account_manager = account_manager.pop();
      setAccountManager(account_manager);
    } else {
      setAccountManager(false);
    }
  }, [data]);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Account Manager</Card.Title>
      </Card.Header>
      {loading && (
        <Card.Body>
          <Loader />
        </Card.Body>
      )}
      {!loading && accountManager ? (
        <Card.Body>
          <Row className="g-0">
            <Col sm="3" xl="12" className="col-xxl-3 text-center">
              <img
                src={accountManager?.avatar}
                width="64"
                height="64"
                className="rounded-circle mt-2"
                alt="Account Manager"
              />
            </Col>
            <Col sm="9" xl="12" className="col-xxl-9">
              <strong>{accountManager?.name}</strong>
              <p>
                {accountManager?.name} is your Wavenet Account Manager. You can
                contact them using the details below.
              </p>
            </Col>
          </Row>

          <Table size="sm" className="my-2">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{accountManager?.name}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{accountManager?.email}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      ) : (
        <Card.Body>
          <NoData noDataMessage="Could not retrieve the account manager for this account." />
        </Card.Body>
      )}
    </Card>
  );
};

const AccountDetails = ({ accountdetails }) => {
  const formatAddress = (address) => {
    const lines = [
      address?.line1,
      address?.line2,
      address?.line3,
      address?.postCode,
    ].filter(Boolean);
    return lines.join(", ");
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Account Details</Card.Title>
      </Card.Header>
      <Card.Body>
        {accountdetails?.accountNumber ? (
          <Table size="sm">
            <tbody>
              <tr>
                <th>Account Number</th>
                <td>{accountdetails?.accountNumber}</td>
              </tr>
              <tr>
                <th>Billing Address</th>
                <td>{formatAddress(accountdetails?.address)}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <NoData />
        )}
      </Card.Body>
    </Card>
  );
};

const Timeline = () => (
  <IsAllowed to="view:customers:timeline">
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">Recent Activity Timeline</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <ul className="timeline">
          <li className="timeline-item">
            <Badge bg="" className="badge-soft-danger me-2">
              <LifeBuoy size={14} /> New Ticket
            </Badge>
            <strong>#208303: Please remove me from your ...</strong>
            <span className="float-end text-muted text-sm">30m ago</span>
            <p>
              <u>Meta Inc</u> opened ticket with reference <u>#208303</u>:
              "Could you please remove me from your mailing list, I am no longer
              a customer. I have asked several times before now a..."
            </p>
          </li>
          <li className="timeline-item">
            <Badge bg="" className="badge-soft-info me-2">
              <Download size={14} /> Customer Moved In
            </Badge>
            <strong>Meta Inc moved in to Metal Box F...</strong>
            <span className="float-end text-muted text-sm">2h ago</span>
            <p>
              Sed aliquam ultrices mauris. Integer ante arcu, accumsan a,
              consectetuer eget, posuere ut, mauris. Praesent adipiscing.
              Phasellus ullamcorper ipsum rutrum nunc...
            </p>
          </li>
          <li className="timeline-item">
            <Badge bg="" className="badge-soft-info me-2">
              <Upload size={14} /> Customer Moved Out
            </Badge>
            <strong>Meta Inc moved out of Ink Room...</strong>
            <span className="float-end text-muted text-sm">3h ago</span>
            <p>
              Curabitur ligula sapien, tincidunt non, euismod vitae, posuere
              imperdiet, leo. Maecenas malesuada...
            </p>
          </li>
        </ul>
      </Card.Body>
    </Card>
  </IsAllowed>
);

const CustomerProfile = () => {
  const { account } = useParams();
  const { userAccessToken, isAuthenticated } = useAuth();
  const [customerData, setCustomerData] = useState({});
  const [keyContacts, setKeyContacts] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  //const [loading, setLoading] = useState(true);
  //const [noData, setNoData] = useState(false);
  React.useEffect(() => {
    let isMounted = true;
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      let symbillApi =
        apiConfig.symbillApiUrl + "newsymbillapi/byAccountNumber/" + account;
      let hubspotApi = apiConfig.hubspotApiUrl + "contacts/company/" + account;
      let hubspotCompanyApi =
        apiConfig.hubspotApiUrl + "companies/company/" + account;
      const symbillApiRequest = axios.get(symbillApi, axiosConfig);
      const hubspotApiRequest = axios.get(hubspotApi, axiosConfig);
      const hubspotCompanyApiRequest = axios.get(
        hubspotCompanyApi,
        axiosConfig
      );
      axios
        .all([symbillApiRequest, hubspotApiRequest, hubspotCompanyApiRequest])
        .then(
          axios.spread((...responses) => {
            if (isMounted) {
              setLoading(false);
              setCustomerData({
                symbillProfile: responses[0].data,
                hubspotProfile: responses[2].data,
              });
              setKeyContacts(responses[1].data);
            }
          })
        )
        .catch((err) => {
          setLoading(false);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [bearerToken, account, isAuthenticated]);

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:customers:profile" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Profile" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-0">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate("/customers/customers")}>
              Customers
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Customer Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Customer Profile
            <OffcanvasHelp
              id="customerprofile"
              name="Customer Profile"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix"></div>
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-5" />
            </>
          )}
          {!loading && (
            <Row>
              <Col md="9" xl="8">
                <ProfileDetails
                  accountnumber={customerData?.symbillProfile?.accountNumber}
                  name={customerData?.symbillProfile?.companyName}
                  domain={
                    customerData?.hubspotProfile?.properties?.domain || "--"
                  }
                  phone={
                    customerData?.hubspotProfile?.properties?.phone ||
                    customerData?.symbillProfile?.telephone ||
                    "--"
                  }
                  email={customerData?.symbillProfile?.email || "--"}
                  description={
                    customerData?.hubspotProfile?.properties?.description || ""
                  }
                />
                <IsAllowed to="view:customers:tenancies">
                  <CustomerTenanciesTable accountnumber={account} />
                </IsAllowed>
                <IsAllowed to="view:tickets">
                  <CustomerTicketsTable accountnumber={account} />
                </IsAllowed>
                <IsAllowed to="view:billing">
                  <CustomerInvoicesTable accountnumber={account} />
                </IsAllowed>
              </Col>
              <Col md="3" xl="4">
                <IsAllowed to="view:customers:profile">
                  <AccountDetails
                    accountdetails={customerData.symbillProfile}
                  />
                </IsAllowed>
                <AccountManager data={keyContacts} loading={loading} />
                <KeyContacts data={keyContacts} loading={loading} />
                <IsAllowed to="view:customers:timeline">
                  <Timeline />
                </IsAllowed>
                <GetSupport type="commercial" />
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

const OrgCustomerProfile = () => {
  const selectedAccount = useSelector((state) => state.accountswitcher);
  const account = selectedAccount.current_account;
  const { userAccessToken, isAuthenticated } = useAuth();
  const [customerData, setCustomerData] = useState({});
  const [keyContacts, setKeyContacts] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  //const [loading, setLoading] = useState(true);
  //const [noData, setNoData] = useState(false);
  React.useEffect(() => {
    let isMounted = true;
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      let symbillApi =
        apiConfig.symbillApiUrl + "newsymbillapi/byAccountNumber/" + account;
      let hubspotContactsApi =
        apiConfig.hubspotApiUrl + "contacts/company/" + account;
      let hubspotCompanyApi =
        apiConfig.hubspotApiUrl + "companies/company/" + account;
      const symbillApiRequest = axios.get(symbillApi, axiosConfig);
      const hubspotContactsApiRequest = axios.get(
        hubspotContactsApi,
        axiosConfig
      );
      const hubspotCompanyApiRequest = axios.get(
        hubspotCompanyApi,
        axiosConfig
      );
      axios
        .all([
          symbillApiRequest,
          hubspotContactsApiRequest,
          hubspotCompanyApiRequest,
        ])
        .then(
          axios.spread((...responses) => {
            if (isMounted) {
              setLoading(false);
              setCustomerData({
                symbillProfile: responses[0].data,
                hubspotProfile: responses[2].data,
              });
              setKeyContacts(responses[1].data);
            }
          })
        )
        .catch((err) => {
          setLoading(false);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [bearerToken, account, isAuthenticated]);

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:customers:profile" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Profile" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-0">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate("/customers/customers")}>
              Customers
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Customer Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Customer Profile
            <OffcanvasHelp
              id="customerprofile"
              name="Customer Profile"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix"></div>
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-5" />
            </>
          )}
          {!loading && (
            <Row>
              <Col md="9" xl="8">
                <ProfileDetails
                  accountnumber={customerData?.symbillProfile?.accountNumber}
                  name={customerData?.symbillProfile?.companyName}
                  domain={
                    customerData?.hubspotProfile?.properties?.domain || "--"
                  }
                  phone={
                    customerData?.hubspotProfile?.properties?.phone || "--"
                  }
                  email={customerData?.symbillProfile?.email || "--"}
                  description={
                    customerData?.hubspotProfile?.properties?.description || ""
                  }
                />
                <IsAllowed to="view:customers:tenancies">
                  <CustomerTenanciesTable accountnumber={account} />
                </IsAllowed>
                <IsAllowed to="view:tickets">
                  <CustomerTicketsTable accountnumber={account} />
                </IsAllowed>
                <IsAllowed to="view:billing">
                  <CustomerInvoicesTable accountnumber={account} />
                </IsAllowed>
              </Col>
              <Col md="3" xl="4">
                <IsAllowed to="view:customers:profile">
                  <AccountDetails
                    accountdetails={customerData?.symbillProfile}
                  />
                </IsAllowed>
                <AccountManager data={keyContacts} />
                <KeyContacts data={keyContacts} />
                <IsAllowed to="view:customers:timeline">
                  <Timeline />
                </IsAllowed>
                <GetSupport type="commercial" />
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default CustomerProfile;
export { OrgCustomerProfile };
