import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

import {
  Activity,
  Bell,
  Inbox,
  UserPlus,
  CheckCircle,
  XCircle,
  Send,
  ShoppingCart,
} from "react-feather";

import useSidebar from "../../hooks/useSidebar";

import IsAllowed from "../IsAllowed";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarUser from "./NavbarUser";
import NavbarAccountSwitcher from "./NavbarAccountSwitcher";

import axios from "axios";
import { apiConfig } from "../../config";
import NavbarNewLandlordSwitcher from "./NavbarNewLandlordSwitcher";

import { ReactComponent as Loader } from "../../assets/img/wavenet-animated-loader3.svg";

const NavbarComponent = () => {
  const { behavior, setBehavior } = useSidebar();
  var toggleSidebar = behavior === "compact" ? "sticky" : "compact";

  const { user, userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [notificationsList, setNotificationsList] = useState([]);
  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (user && isAuthenticated) {
      axios
        .get(apiConfig.usersApiUrl + "notifications/", axiosConfig)
        .then((response) => {
          setNotificationsList(response.data);
        })
        .catch((err) => {});
    }
  }, [user, bearerToken, isAuthenticated]);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setBehavior(toggleSidebar);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      {user && isAuthenticated ? (
        <Navbar.Collapse>
          <Nav className="navbar-align">
            <IsAllowed to="view:landlordswitcher">
              <NavbarNewLandlordSwitcher />
            </IsAllowed>
            <NavbarAccountSwitcher />
            <IsAllowed to="view:notifications">
              <NavbarDropdown
                header="New Notifications"
                footer="View all notifications"
                icon={Bell}
                count={notificationsList.unreadCount}
                showBadge={notificationsList.unreadCount > 0 ? true : false}
              >
                {notificationsList?.notifications
                  ?.slice(0, 50)
                  .map((item, key) => {
                    let icon = <Bell size={18} className="text-secondary" />;
                    let unread = "";

                    if (item.type.startsWith("network")) {
                      icon = <Activity size={18} className="text-info" />;
                    }

                    if (item.type.startsWith("tickets")) {
                      icon = <Inbox size={18} className="text-primary" />;
                    }

                    if (item.type.startsWith("users")) {
                      icon = <UserPlus size={18} className="text-success" />;
                    }

                    if (item.type.startsWith("orders.registrationApproved")) {
                      icon = <CheckCircle size={18} className="text-success" />;
                    }

                    if (item.type.startsWith("orders.registrationRejected")) {
                      icon = <XCircle size={18} className="text-danger" />;
                    }

                    if (item.type.startsWith("orders.registrationCompleted")) {
                      icon = <Send size={18} className="text-success" />;
                    }

                    if (item.type.startsWith("orders.orderSigned")) {
                      icon = (
                        <ShoppingCart size={18} className="text-success" />
                      );
                    }

                    if (item.unread === 1) {
                      unread = "notification-unread";
                    }

                    return (
                      <NavbarDropdownItem
                        key={key}
                        notification_id={item.id}
                        icon={icon}
                        title={item.title}
                        description={item.description}
                        time={item.time}
                        unread={unread}
                        link={item.link}
                      />
                    );
                  })}
              </NavbarDropdown>
            </IsAllowed>
            <NavbarUser />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <div height="20px" width="auto" className="navbar-align">
          <Loader className="float-end" />
        </div>
      )}
    </Navbar>
  );
};

export default NavbarComponent;
