import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as LogoLoader } from "../assets/img/wavenet-animated-loader3.svg";

const LongDataLoader = ({ textSelector = "default" }) => {
  const [index, setIndex] = useState(0);
  const loadingTextsData = {
    default: [
      "Loading information...",
      "Formatting data...",
      "Organising displays...",
      "Wrapping things up...",
      "Nearly there...",
      "Thank you for your patience",
    ],
    monitoring: [
      "Loading network info...",
      "Formatting graph data...",
      "Organising device inventory...",
      "Wrapping things up...",
      "Nearly there...",
      "Thank you for your patience",
    ],
  };

  const loadingTexts =
    loadingTextsData[textSelector] || loadingTextsData.default;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 2500);

    return () => clearInterval(intervalId);
  }, [loadingTexts]);

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center long-loader-container"
    >
      <div className="text-center">
        <LogoLoader className="long-logo-loader" />
        <div className="long-loading-text">{loadingTexts[index]}</div>
      </div>
    </Container>
  );
};

export default LongDataLoader;
