import "./process-polyfill";
import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

//import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/Auth0Context";

import { PermissionsProvider } from "./contexts/PermissionsContext";
import { SettingsProvider, useSettings } from "./contexts/SettingsContext";
import MaintenanceMode from "./components/MaintenanceMode";

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AppContent content={content} />
    </SettingsProvider>
  );
};

const AppContent = ({ content }) => {
  const { isInMaintenanceMode } = useSettings();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Wavenet Connected Gateway"
        defaultTitle="Wavenet Connected Gateway - Managed your Connected services"
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <ChartJsDefaults />
              <AuthProvider>
                <PermissionsProvider>
                  {isInMaintenanceMode ? <MaintenanceMode /> : content}
                </PermissionsProvider>
              </AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
