import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { Card } from "react-bootstrap";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";

import usePalette from "../../../hooks/usePalette";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../config";

import { useSelector } from "react-redux";
import NotFound from "../../../components/NotFound";
import { useNavigate } from "react-router-dom";

const NetworkSLABar = () => {
  const palette = usePalette();
  const { userAccessToken } = useAuth();
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      setLoading(true);
      setNoData(false);

      try {
        const axiosConfig = {
          headers: { Authorization: `Bearer ${userAccessToken}` },
        };

        const response = await axios.get(
          `${apiConfig.monitoringApiUrl}core/buildingavailability/${selectedLandlord.landlordid}`,
          axiosConfig
        );

        const data = response.data;

        if (data.length === 0) {
          setNoData(true);
          setLoading(false);
          return;
        }

        const labels = data.map((x) => `${x.building_name} | ${x.building_id}`);
        const datapoints = data.map((x) => x.availability);
        const colors = data.map((x) =>
          x.availability >= 99.95 ? palette.lightgrey : palette.connectedorange
        );

        setChartOptions({
          chart: {
            type: "bar",
            toolbar: {
              tools: {
                download: true,
              },
            },
            responsive: true,
            events: {
              click: function (event, chartContext, { dataPointIndex }) {
                if (dataPointIndex >= 0) {
                  const buildingLabel = labels[dataPointIndex];
                  const building_id = buildingLabel.split(" | ").pop();
                  navigate(`/monitoring/building/${building_id}`);
                }
              },
            },
          },
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              distributed: true,
              borderRadius: 2,
              horizontal: false,
              barHeight: "70%",
            },
          },
          dataLabels: {
            enabled: false,
            formatter: (val) => `${val}%`,
          },
          xaxis: {
            categories: labels,
            labels: {
              rotate: -45,
              style: {
                fontSize: "10px",
              },
              formatter: (value) =>
                value.length > 10 ? `${value.substring(0, 10)}...` : value,
            },
            title: {
              text: "Buildings",
            },
          },
          yaxis: {
            title: {
              text: "Network Uptime %",
            },
            labels: {
              formatter: (value) => `${value}%`,
            },
          },
          colors: colors,
          tooltip: {
            shared: true,
            intersect: false,
            x: {
              formatter: (value, opts) =>
                labels[opts.dataPointIndex].split(" | ")[0],
            },
            y: {
              formatter: (val) => `${val}%`,
            },
          },
        });

        setChartSeries([
          {
            name: "Network Uptime %",
            data: datapoints,
          },
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setNoData(true);
        setLoading(false);
      }
    };

    fetchChartData();
  }, [userAccessToken, selectedLandlord, palette, navigate]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Building Availability</Card.Title>
      </Card.Header>
      <Card.Body className="">
        <div
          className="align-self-center w-100"
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="chart chart-lg"
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && <LogoLoader className="d-block m-auto p-1" />}
            {!loading && noData && <NotFound />}
            {!loading && !noData && (
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="100%"
                width="100%"
              />
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NetworkSLABar;
