import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import axios from "axios";
import moment from "moment";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import NotFound from "../../components/NotFound";
import MultiPageForm from "../../components/modals/MultiPageForm";
import IsAllowed from "../../components/IsAllowed";
import formConfig from "./formConfigs/customerRegistration";
import formConfig__landlord from "./formConfigs/customerRegistration__landlord";

const CustomerRegistrationsList = () => {
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [registrationsData, setRegistrationsData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [formPrefillData, setFormPrefillData] = useState({});
  const [formInitialPage, setFormInitialPage] = useState(1);

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index, prefillData = {}, initialPage = 1) => {
    if (Object.keys(prefillData).length > 0) {
      setFormPrefillData(prefillData);
      setFormInitialPage(initialPage);
    }

    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (!isAuthenticated) {
      return;
    }
    axios
      .get(
        `${apiConfig.hubspotApiUrl}companies/signups/operator/${selectedLandlord.landlordid}`,
        axiosConfig
      )
      .then((response) => {
        setRegistrationsData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setNoData(true);
        setLoading(false);
        setRegistrationsData([]);
      });
  }, [isAuthenticated, bearerToken, selectedLandlord.landlordid, refreshData]);

  const statusColourMap = {
    draft: "secondary",
    pending: "primary",
    completed: "info",
    approved: "success",
    rejected: "danger",
  };
  const registrationsListColumns = [
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <Badge bg={statusColourMap[e.value]}>
          {e.row.original.status.charAt(0).toUpperCase() +
            e.row.original.status.slice(1)}
        </Badge>
      ),
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY"),
    },
    {
      Header: "Updated",
      accessor: "updated",
      Cell: (e) =>
        e.value ? moment(e.value).local().format("DD-MM-YYYY") : "",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => {
        const { id, status, hubspot_customer_id } = tableProps.row.original;
        return (
          <>
            <Button
              size="sm"
              className="btn-light m-1"
              onClick={() => {
                navigate(`/customers/registrations/edit/${id}`);
              }}
            >
              {status === "draft" && "Edit Registration Form"}
              {status !== "draft" && "View Registration Form"}
            </Button>
            {hubspot_customer_id && (
              <Button
                size="sm"
                className="btn-primary m-1"
                onClick={() => {
                  window.open(
                    `https://app.hubspot.com/contacts/3402328/company/${hubspot_customer_id}`,
                    "_blank"
                  );
                }}
              >
                View Hubspot Company
              </Button>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Card>
        <Card.Header>
          <div className="card-actions float-end">
            <DropdownButton
              as={ButtonGroup}
              id={`dropdown-variants-primary`}
              variant={`primary`}
              title="Actions "
              align="end"
            >
              <IsAllowed to="create:signups:all">
                <Dropdown.Item onClick={() => toggle("customerRegistration")}>
                  New Customer Registration
                </Dropdown.Item>
              </IsAllowed>
              <IsAllowed to="create:signups:landlord">
                <Dropdown.Item
                  onClick={() => toggle("customerRegistration__landlord")}
                >
                  New Customer Registration
                </Dropdown.Item>
              </IsAllowed>
            </DropdownButton>
          </div>
          <Card.Title className="mb-0">Customer Registrations List</Card.Title>
        </Card.Header>
        <Card.Body className="py-0">
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-4" />
            </>
          )}
          {!loading && noData && <NotFound />}
          {!loading && !noData && (
            <ColumnFilteringTable
              columns={registrationsListColumns}
              data={registrationsData}
            />
          )}
        </Card.Body>
      </Card>
      <IsAllowed to="create:signups:all">
        <MultiPageForm
          openModals={openModals}
          toggle={toggle}
          config={formConfig}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          prefillData={formPrefillData}
          initialPage={formInitialPage}
        />
      </IsAllowed>
      <IsAllowed to="create:signups:landlord">
        <MultiPageForm
          openModals={openModals}
          toggle={toggle}
          config={formConfig__landlord}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          prefillData={formPrefillData}
          initialPage={formInitialPage}
        />
      </IsAllowed>
    </>
  );
};

export default CustomerRegistrationsList;
