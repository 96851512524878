import React, { useState, useContext } from "react";
import {
  Tab,
  Card,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { apiConfig } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import NotyfContext from "../../../contexts/NotyfContext";
import IsAllowed from "../../../components/IsAllowed";
import NotAuthorised from "../../../components/NotAuthorised";
import NotFound from "../../../components/NotFound";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ColumnFilteringTable from "../../../components/tables/ColumnFilteringTable";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";
import { setRefreshData } from "../../../redux/slices/refreshData";
import AddNewSSIDModal from "./AddNewSSIDModal";
import DeleteSSIDModal from "./DeleteSSIDModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";

const RadiusSSIDs = () => {
  const dispatch = useDispatch();
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const [landlordOptions, setLandlordOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [SSIDs, setSSIDs] = useState([]);
  const refreshData = useSelector((state) => state.refreshdata);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.credentialsApiUrl + "ssids/", axiosConfig)
      .then(function (response) {
        setSSIDs(response.data.ssids.rows);
        setLoading(false);
        setNoData(false);
      })
      .catch(function (error) {
        setSSIDs([]);
        setLoading(false);
        setNoData(true);
      });
  }, [selectedLandlord, bearerToken, refreshData]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.connectedApiUrl + "operators?page_size=1000", axiosConfig)
      .then(function (response) {
        let landlordsList = response.data.operators.items.map((operator) => ({
          value: operator.id,
          label: operator.operator_name,
        }));
        setLandlordOptions(landlordsList);
      })
      .catch(function (error) {});
  }, [bearerToken]);

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };
  const [passedData, setPassedData] = useState({});
  const passData = (data) => {
    setPassedData(data);
  };

  const tableColumns = [
    {
      Header: "SSID",
      accessor: "ssid",
    },
    {
      Header: "Landlord",
      accessor: "building_operator_id",
      Cell: (e) => {
        let landlord = landlordOptions.find(
          (landlord) =>
            parseInt(landlord.value) ===
            parseInt(e.row.original.building_operator_id)
        );
        return landlord ? landlord.label : "N/A";
      },
    },
    {
      Header: "Credential Type",
      accessor: "credential_type",
    },
    {
      Header: "Enabed?",
      accessor: "enabled",
      Cell: ({ value }) => (parseInt(value) === 1 ? "Yes" : "No"),
    },
    {
      Header: "Settings",
      accessor: "wlan_settings",
      Cell: (e) => {
        let settingsArray = e.row.original.wlan_settings;
        let settingsStrings = settingsArray.map((setting) => {
          return setting.setting_name + ": " + setting.setting_value;
        });
        return <pre>{settingsStrings.join("\n")}</pre>;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <IsAllowed to="edit:credentials">
            <FontAwesomeIcon
              icon={faPowerOff}
              className="ms-2"
              onClick={() => {
                switchSSID(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
          <IsAllowed to="delete:credentials">
            <FontAwesomeIcon
              icon={faTrash}
              className="ms-2"
              onClick={() => {
                toggle("deleteSSID");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
      disableFilters: true,
    },
  ];

  const switchSSID = (data) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.credentialsApiUrl + `ssids/${data.id}`,
        {
          enabled: !data.enabled,
        },
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setRefreshData());
          setTimeout(() => dispatch(setRefreshData()), 2000);
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  return (
    <>
      <Tab.Pane eventKey="#RadiusSSIDs">
        <IsAllowed to="view:systemsettings:users" fallback={<NotAuthorised />}>
          <Card.Header>
            <div className="card-actions float-end">
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-variants-primary`}
                variant={`primary`}
                title="Manage "
                align="end"
              >
                <Dropdown.Item onClick={() => toggle("addNewSSID")}>
                  Add New SSID
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <Card.Title className="mb-0">Manage RADIUS SSIDs</Card.Title>
            Manage the SSIDs which are enabled on the (New) RADIUS server here.
            Only enabled SSIDs assigned to each landlord will be available to
            create users against in the Add Dot1X Credential form.
          </Card.Header>
          <Card.Body className="py-0">
            {loading && (
              <>
                <LogoLoader className="d-block m-auto p-4" />
              </>
            )}
            {!loading && noData && <NotFound />}
            {!loading && !noData && (
              <ColumnFilteringTable
                columns={tableColumns}
                data={SSIDs}
                actions
                hiddenColumns={[]}
              />
            )}
          </Card.Body>
        </IsAllowed>
      </Tab.Pane>
      <AddNewSSIDModal
        openModals={openModals}
        toggle={toggle}
        landlordOptions={landlordOptions}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />
      <DeleteSSIDModal
        openModals={openModals}
        toggle={toggle}
        passedData={passedData}
        dispatch={dispatch}
        setRefreshData={setRefreshData}
      />
    </>
  );
};

export default RadiusSSIDs;
