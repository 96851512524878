import React, { useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faTrash } from "@fortawesome/free-solid-svg-icons";
import NotFound from "../../components/NotFound";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "../../redux/slices/refreshData";
import NotyfContext from "../../contexts/NotyfContext";

import axios from "axios";
import { apiConfig } from "../../config";

const GroupsTable = () => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [groupsList, setGroupsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const refreshData = useSelector((state) => state.refreshdata);

  const dispatch = useDispatch();

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };
  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const groupsColumns = [
    {
      Header: "Group Name",
      accessor: "group_name",
    },
    {
      Header: "Group Members",
      accessor: "group_members_json",
      Cell: ({ value }) => {
        return JSON.parse(value).map((member) => {
          return (
            <div key={member.user_id}>
              {member.user_name} ({member.user_id})
            </div>
          );
        });
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <FontAwesomeIcon
            icon={faTrash}
            className="ms-2"
            onClick={() => {
              deleteGroup(row.original.id);
              setTimeout(() => {
                dispatch(setRefreshData());
              }, 2000);
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  const deleteGroup = (groupId) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .delete(
        `${apiConfig.usersApiUrl}notifications/groups/${groupId}`,
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/groups/", axiosConfig)
      .then((response) => {
        setGroupsList(response.data);
        setLoading(false);
        if (response.status === 204) {
          setNoData(false);
          setGroupsList([]);
        } else {
          setNoData(false);
        }
      })
      .catch((error) => {
        setGroupsList([]);
        setLoading(false);
        setNoData(true);
      });
  }, [bearerToken, refreshData]);

  const [groupName, setGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);

  const loadUserOptions = (inputValue) => {
    if (inputValue.length < 3) {
      return [];
    }
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    return axios
      .get(apiConfig.usersApiUrl + "users/search/?q=" + inputValue, axiosConfig)
      .then((response) => {
        const res = response.data;
        return res.map((user) => ({
          value: user,
          label: `${user.name} (${user.email})`,
        }));
      })
      .catch((err) => {
        console.error("Error fetching data: ", err);
        return [];
      });
  };

  const resetFormFields = () => {
    setGroupName("");
    setGroupMembers([]);
  };

  const handleCreateGroupSubmit = (event) => {
    event.preventDefault();
    const createGroupBody = {
      groupName: groupName,
      groupMembers: groupMembers,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(
        apiConfig.usersApiUrl + "notifications/groups/",
        createGroupBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 201) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
    resetFormFields();
  };

  return (
    <>
      {loading && (
        <>
          <LogoLoader className="d-block m-auto p-4" />
        </>
      )}
      {!loading && noData && <NotFound />}
      {!loading && !noData && (
        <>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => toggle("addGroup")}
          >
            <FontAwesomeIcon icon={faUsers} className="me-1" /> Add Notification
            Group
          </Button>
          <ColumnFilteringTable
            columns={groupsColumns}
            data={groupsList || []}
            actions
          />
        </>
      )}

      <Modal
        show={openModals["addGroup"]}
        onHide={() => {
          toggle("addGroup");
          resetFormFields();
        }}
        centered
      >
        <Form onSubmit={handleCreateGroupSubmit}>
          <Modal.Header closeButton>
            <b>Notification Groups:</b>&nbsp; Create Notification Group
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <Form.Group className="mb-3">
                  <Form.Label>1. Name the new notification group</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter group name"
                    onChange={(name) => {
                      setGroupName(name.target.value);
                    }}
                    value={groupName}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    2. Search and add members to this group
                  </Form.Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(selectedOptions) => {
                      setGroupMembers(selectedOptions);
                    }}
                    value={groupMembers}
                    loadOptions={loadUserOptions}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti
                    placeholder="Search for users..."
                  />
                </Form.Group>
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                toggle("addGroup");
                resetFormFields();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={false}
              onClick={() => {
                toggle("addGroup");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Create Group
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default GroupsTable;
