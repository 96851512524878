import React, { useMemo, useCallback } from "react";

import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Server, Wifi, Battery } from "react-feather";

import ColumnSortingTable from "../../components/tables/DeviceInventoryTable";
import InventorySelectorFilters from "./InventorySelectorFilters";

const DeviceSelector = ({ data, selectedDevice, setSelectedDevice }) => {
  const columns = [
    {
      accessor: "deviceInfo.device_role",
      Header: "Type",
      sort: true,
      Cell: (e) => {
        return (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-right">{e.value}</Tooltip>}
          >
            <span>
              {e.value === "Wireless Access Point" ? (
                <Wifi />
              ) : e.value === "UPS" ? (
                <Battery />
              ) : (
                <Server />
              )}
            </span>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "deviceInfo.device_hostname",
      Header: "Hostname",
      sort: true,
    },
    {
      accessor: "deviceInfo.location_name",
      Header: "Location",
      sort: true,
    },
    {
      accessor: "deviceInfo.id",
      Header: "Inventory ID",
    },
  ];

  const handleRowClick = (rowInfo) => {
    setSelectedDevice({
      deviceName: rowInfo.original.deviceInfo.device_hostname,
      deviceInventoryId: rowInfo.original.deviceInfo.id,
      inventorySource: "nautobot",
    });
  };

  const [filters, setFilters] = React.useState([]);

  const devices = data.monitoring;

  const generateFilterOptions = (devices) => {
    const deviceRoles = new Set();
    const deviceLocations = new Set();

    devices.forEach((device) => {
      deviceRoles.add(device.deviceInfo.device_role);
      deviceLocations.add(device.deviceInfo.location_name);
    });

    return {
      deviceType: {
        label: "Device Type",
        accessor: "deviceInfo.device_role",
        options: Array.from(deviceRoles),
      },
      location: {
        label: "Location",
        accessor: "deviceInfo.location_name",
        options: Array.from(deviceLocations),
      },
    };
  };

  const filterOptions = generateFilterOptions(devices);

  const groupFiltersByAccessor = useCallback((filters) => {
    return filters.reduce((acc, filter) => {
      if (!acc[filter.id]) {
        acc[filter.id] = [];
      }
      acc[filter.id].push(filter.value);
      return acc;
    }, {});
  }, []);
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const applyFilters = useCallback(
    (data, filters) => {
      const groupedFilters = groupFiltersByAccessor(filters);
      return data.filter((row) => {
        return Object.keys(groupedFilters).every((accessor) => {
          const filterValues = groupedFilters[accessor];
          return filterValues.some((filterValue) => {
            const rowValue = getNestedValue(row, accessor);
            return rowValue !== undefined && String(rowValue) === filterValue;
          });
        });
      });
    },
    [groupFiltersByAccessor]
  );
  const filteredData = useMemo(() => {
    return applyFilters(devices, filters);
  }, [devices, filters, applyFilters]);

  return (
    <Card className="flex-fill">
      <Card.Header>
        <Card.Title className="mb-3">Device Inventory</Card.Title>
        Select a device below to view device information and all available
        monitoring stats.
        <br />
        <small className="text-muted">
          Selected: {selectedDevice.deviceName || "None"}
        </small>
      </Card.Header>
      <Card.Body className="py-0">
        <InventorySelectorFilters
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
        />
        <ColumnSortingTable
          columns={columns}
          data={filteredData}
          defaultSorted={[
            {
              dataField: "device_name",
              order: "asc",
            },
          ]}
          hiddenColumns={[
            "deviceInfo.location_type",
            "deviceInfo.location_id",
            "deviceInfo.id",
          ]}
          onRowClick={handleRowClick}
          selectedDevice={selectedDevice}
        />
      </Card.Body>
    </Card>
  );
};

export default DeviceSelector;
