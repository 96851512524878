import * as Yup from "yup";
import axios from "axios";
import { apiConfig } from "../../../config";
import { io } from "socket.io-client";

const generateManifest = (formData, token) => {
  let data = {};

  let xconnect = formData.vlanSelection.value === "XCONNECT" ? true : false;

  if (formData.customer.value === "WN000001") {
    data = {
      tenancyId: 99999998,
      services: [
        {
          service: "OSIA",
          params: {
            staticIp: formData.staticIp,
            xconnect: xconnect,
            vlan: "251",
            subnet: "10.74.251.1",
            mask: "/24",
          },
        },
      ],
    };
  } else if (formData.customer.value === "L8000001") {
    data = {
      tenancyId: 99999999,
      services: [
        {
          service: "OSIA",
          params: {
            staticIp: formData.staticIp,
            xconnect: xconnect,
            vlan: "103",
            subnet: "192.168.103.1",
            mask: "/24",
          },
        },
      ],
    };
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${apiConfig.monitoringApiUrl}automation/generateManifest`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const assignPublicIp = (
  previousTask,
  token,
  { myFormData, updateHandler, task }
) => {
  return new Promise((resolve, reject) => {
    if (!myFormData.staticIp || myFormData.staticIp === "") {
      updateHandler(
        task.name,
        "succeeded",
        "No static IP address requested",
        "No static IP address requested"
      );
      resolve({
        message: "No Static IP address requested.",
        payload: "No static IP address requested.",
      });
    } else {
      // Currently hardcoded to location ID 9999 for testing
      // TODO: Get location ID from form ... from the generated manifest.
      axios
        .get(
          `${apiConfig.monitoringApiUrl}automation/publicIp/assign/9999/${myFormData.customer.value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          updateHandler(
            task.name,
            "succeeded",
            "Public Static IP address assigned successfully.",
            response.data
          );
          resolve({
            message: "Public Static IP address assigned successfully.",
            payload: response.data,
          });
        })
        .catch((error) => {
          updateHandler(task.name, "failed", error.error, error);
          reject({
            message: "Could not assign a Public Static IP address.",
            payload: error.response.data.error,
          });
        });
    }
  });
};

const runNetworkConfiguration = (manifest, token, task) => {
  const parsedManifest = JSON.parse(manifest);
  return new Promise((resolve, reject) => {
    axios
      .post(`http://localhost:5001/start_workflow`, parsedManifest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.task_id) {
          const socket = io("http://localhost:5001", { autoConnect: false });
          socket.open();
          let socket_messages = "";
          socket.on(`task_update_${response.data.task_id}`, (msg) => {
            if (msg.completed) {
              socket_messages += msg.data + "\n\n---- End of Workflow ----\n\n";
              if (msg.withErrors) {
                task.updateHandler(
                  task.task.name,
                  "failed",
                  msg.data,
                  socket_messages
                );
                socket.off(`task_update_${response.data.task_id}`);
                socket.close();
                reject({
                  message: "Network configuration applied with some errors.",
                  payload: socket_messages,
                });
              } else {
                task.updateHandler(
                  task.task.name,
                  "succeeded",
                  msg.data,
                  socket_messages
                );
                socket.off(`task_update_${response.data.task_id}`);
                socket.close();
                resolve({
                  message: "Network configuration applied successfully.",
                  payload: socket_messages,
                });
              }
            }
            socket_messages += msg.data + "\n\n----\n\n";
            task.updateHandler(
              task.task.name,
              "loading",
              msg.data,
              socket_messages
            );
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const taskHandlers = {
  "Validate network configuration request": generateManifest,
  "Run network configuration workflow": runNetworkConfiguration,
  "Assign static IP address": assignPublicIp,
};

const formConfig = {
  name: "customerMoveIn",
  title: "PROVISION CUSTOMER NETWORK",
  steps: [
    {
      stepType: "input",
      title: "Customer Details",
      description: "Select which customer to move-in",
      fields: [
        {
          name: "customer",
          label: "Select Customer",
          confirmLabel: "Customer",
          type: "dynamicSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.symbillApiUrl}newsymbillapi/byCtidList/${selectedLandlord.landlordid}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.map((customer) => ({
                    label: `${customer.companyName} (${customer.accountNumber})`,
                    value: customer.accountNumber,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: Yup.string().required("Customer is required"),
        },
      ],
      validationSchema: Yup.object().shape({
        customer: Yup.object()
          .shape({
            value: Yup.string().required("Customer selection is required"),
          })
          .required("Customer is required"),
      }),
    },
    {
      stepType: "input",
      title: "Building & Unit",
      description: "Choose a tenancy location",
      fields: [
        // {
        //   name: "neworexisting",
        //   label: "",
        //   confirmLabel: "New or Existing",
        //   type: "radiotile",
        //   options: [
        //     {
        //       label: "Existing Tenancy",
        //       description:
        //         "Use a tenancy that already exists for this customer",
        //       icon: (
        //         <FontAwesomeIcon
        //           icon={faMagnifyingGlass}
        //           style={{ width: "1vw" }}
        //           className="px-2"
        //           fixedWidth
        //         />
        //       ),
        //       value: "existing",
        //       disabled: false,
        //       defaultChecked: true,
        //     },
        //     {
        //       label: "New Tenancy",
        //       description: "Create a new tenancy now for this customer",
        //       icon: (
        //         <FontAwesomeIcon
        //           icon={faWandMagicSparkles}
        //           className="px-2"
        //           style={{ width: "1vw" }}
        //         />
        //       ),
        //       value: "new",
        //       disabled: true,
        //       defaultChecked: false,
        //     },
        //   ],
        //   validation: null,
        // },
        {
          name: "building",
          label: "Select Building",
          confirmLabel: "Building",
          type: "dynamicSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord,
            myFormData
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.connectedApiUrl}tenancies/withassociations?active_tenancies=true&page_size=100&tenant_id=${myFormData.customer.value}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.tenancies.items.map((tenancy) => ({
                    label: `${tenancy.room.building.building_name}`,
                    value: tenancy.room.building.id,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: Yup.string().required("Building is required"),
        },
        {
          name: "unit",
          label: "Select Unit",
          confirmLabel: "Unit",
          type: "dynamicSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord,
            myFormData
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.connectedApiUrl}tenancies/withassociations?active_tenancies=true&page_size=100&tenant_id=${myFormData.customer.value}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.tenancies.items.map((tenancy) => ({
                    label: `${tenancy.room.room_number}`,
                    value: tenancy.room.id,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: Yup.string().required("Unit is required"),
        },
      ],
      validationSchema: Yup.object().shape({
        building: Yup.object().shape({
          value: Yup.string().required("Building selection is required"),
        }),
        unit: Yup.object().shape({
          value: Yup.string().required("Unit selection is required"),
        }),
      }),
    },
    {
      stepType: "input",
      title: "Service Provisioning",
      description: "Specify service provisioning options",
      fields: [
        {
          name: "vlanSelection",
          title: "Customer VLAN selection",
          label: "Customer VLAN selection",
          confirmLabel: "VLAN for customer network",
          infoBox:
            "Choose whether to assign a standard OSIA VLAN for the customer or a X-Connect VLAN.",
          type: "select",
          options: [
            {
              label: "Standard VLAN",
              value: "AUTO",
              name: "vlanSelection",
            },
            {
              label: "X-Connect VLAN",
              value: "XCONNECT",
              name: "vlanSelection",
            },
          ],
          validation: null,
        },
        {
          name: "staticIp",
          title: "Public Static IP",
          label: "Assign a public static IP address?",
          confirmLabel: "Provision a static IP address",
          infoBox:
            "Do you need to assign a public static IP address for the customer from the available pool for this location?",
          type: "switch",
          validation: null,
        },
      ],
      validationSchema: null,
    },
    {
      stepType: "review",
      title: "Confirm Details",
      confirmationStep: true,
      description: "Review details and create tenancy",
      fields: [
        {
          name: "confirm",
          label: "Confirm that the below details are correct",
          type: "confirm",
          validation: null,
        },
      ],
      validationSchema: null,
    },
    {
      stepType: "process",
      title: "Work Some Magic",
      description: "Run the automated tasks",
      confirmationStep: true,
      fields: [],
    },
  ],
  handleSubmit: async (
    formData,
    {
      submissionStatus,
      setSubmissionStatus,
      userAccessToken,
      updateTaskStatus,
      setIsConfirmationNeeded,
      isConfirmed,
    }
  ) => {
    const tasks = [
      { name: "Validate network configuration request", status: "loading" },
      {
        name: "Run network configuration workflow",
        status: "pending",
      },
    ];

    if (formData.staticIp === true && formData.staticIp !== "") {
      tasks.push({
        name: "Assign static IP address",
        status: "pending",
      });
    }

    let initialSubmissionStatus = {
      isLoading: true,
      tasks: tasks.map((task) => ({ ...task, status: task.status })),
    };
    setSubmissionStatus(initialSubmissionStatus);

    const firstTask = tasks[0];
    const handler = taskHandlers[firstTask.name];
    updateTaskStatus(firstTask.name, "loading", null, "");

    try {
      const result = await handler(formData, userAccessToken);
      updateTaskStatus(
        firstTask.name,
        "succeeded",
        result.message,
        JSON.stringify(result.manifest, null, 2)
      );
      setIsConfirmationNeeded(true);

      // if (isConfirmed) {
      //   tasks.slice(1).forEach((task) => {
      //     updateTaskStatus(task.name, "loading", null, null);

      //     setTimeout(() => {
      //       updateTaskStatus(
      //         task.name,
      //         "succeeded",
      //         "Completed configuration successfully",
      //         {}
      //       );
      //     }, 5000);
      //   });
      // }
    } catch (error) {
      setSubmissionStatus({
        ...submissionStatus,
        isLoading: false,
      });
      updateTaskStatus(firstTask.name, "failed", error.message, null);
    }
  },
  executeNextTask: async ({
    helpers,
    submissionStatus,
    taskIndex,
    setSubmissionStatus,
  }) => {
    const { updateTaskStatus, userAccessToken, myFormData } = helpers;
    const task = submissionStatus.tasks[taskIndex];
    const previousTask = submissionStatus.tasks[taskIndex - 1];
    const handler = taskHandlers[task.name];
    updateTaskStatus(task.name, "loading", null, "");
    try {
      const result = await handler(previousTask.payload, userAccessToken, {
        task: task,
        updateHandler: updateTaskStatus,
        submissionStatusHandler: setSubmissionStatus,
        submissionStatus: submissionStatus,
        myFormData: myFormData,
      });
      updateTaskStatus(task.name, "succeeded", result.message, result.payload);
    } catch (error) {
      updateTaskStatus(task.name, "failed", error.message, error.payload);
    }
  },
  executeAllTasks: async ({
    helpers,
    submissionStatus,
    setSubmissionStatus,
  }) => {
    for (
      let i = submissionStatus.tasks.findIndex(
        (task) => task.status === "pending"
      );
      i < submissionStatus.tasks.length;
      i++
    ) {
      await formConfig.executeNextTask({
        helpers,
        submissionStatus,
        taskIndex: i,
        setSubmissionStatus,
      });
    }
  },
};

export default formConfig;
