import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Tab,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

import useAuth from "../../hooks/useAuth";

import NotyfContext from "../../contexts/NotyfContext";
import axios from "axios";
import { apiConfig } from "../../config";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Profile Settings</Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#1" action>
        Profile Info
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#2" action>
        Login Details
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const ProfileInfo = () => {
  const { user, userAccessToken, isAuthenticated, refreshUser } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  React.useEffect(() => {
    setPhone(user.mobile);
  }, [isAuthenticated, user]);

  React.useEffect(() => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const isMobileValid = (phone) => {
      try {
        return phoneUtil.isValidNumberForRegion(
          phoneUtil.parseAndKeepRawInput(phone),
          "GB"
        );
      } catch (error) {
        return false;
      }
    };
    setIsPhoneValid(isMobileValid(phone));
  }, [phone]);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.usersApiUrl + "users/" + user.email + "/pwreset",
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          notyf.open({
            type,
            message: "A password reset email has been sent to you.",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((err) => {
        notyf.open({
          type: "warning",
          message: err.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  const handleSaveMobile = async (mobile) => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    const updateProfileUrl = `${apiConfig.usersApiUrl}users/`;
    const updateSubscriptionsUrl = `${apiConfig.usersApiUrl}notifications/updateMobile`;

    const updateProfile = axios.patch(
      updateProfileUrl,
      {
        email: user.email,
        role: user.roles,
        accountnumber: user.accountnumber,
        mobile: mobile,
      },
      axiosConfig
    );

    const updateSubscriptions = axios.patch(
      updateSubscriptionsUrl,
      {
        user_mobile: mobile,
      },
      axiosConfig
    );

    const results = await Promise.allSettled([
      updateProfile,
      updateSubscriptions,
    ]);

    results.forEach((result, index) => {
      if (result.status === "fulfilled" && result.value.status === 200) {
      } else if (result.status === "rejected") {
      }
    });

    const allSuccess = results.every(
      (result) => result.status === "fulfilled" && result.value.status === 200
    );

    if (allSuccess) {
      notyf.open({
        type,
        message: "Your mobile number has been saved.",
        duration,
        ripple,
        dismissible,
        position: {
          x: positionX,
          y: positionY,
        },
      });
      refreshUser();
    } else {
      notyf.open({
        type: "warning",
        message: "One or more requests failed.",
        duration,
        ripple,
        dismissible,
        position: {
          x: positionX,
          y: positionY,
        },
      });
    }
  };

  return (
    <Card>
      <Tab.Content>
        <Tab.Pane eventKey="#1">
          <Card.Header>
            <Card.Title className="mb-0">Profile Info</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Form>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputUsername">
                      Username{" "}
                      <span className="text-muted">(email address)</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="inputUsername"
                      placeholder={user.email}
                      disabled
                    />
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="text"
                          id="name"
                          placeholder="Name"
                          defaultValue={user.displayName}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="name">Mobile Number</Form.Label>
                        <InputGroup>
                          <PhoneInput
                            defaultCountry="gb"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            hideDropdown
                            className="mb-2"
                          />
                          <Button
                            variant="secondary"
                            style={{ height: 36 }}
                            disabled={!isPhoneValid}
                            onClick={() => handleSaveMobile(phone)}
                          >
                            Save
                          </Button>
                        </InputGroup>
                      </Form.Group>
                      <small>
                        We use this to send you SMS notifications, if you choose
                        to receive them. We will never share your number with
                        anyone else. We currently only support UK phone numbers.
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" className="justify-content-center">
                  <div className="text-center">
                    <img
                      alt={user.displayName}
                      src={user.avatar}
                      className="rounded-circle img-responsive mt-2"
                      width="128"
                      height="128"
                    />
                    <div className="mt-2">
                      <a
                        href="https://gravatar.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Manage on
                          Gravatar.com
                        </Button>
                      </a>
                    </div>
                    <small>
                      We use Gravatar.com, the global avatar service.
                    </small>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Tab.Pane>
        <Tab.Pane eventKey="#2">
          <Card.Header>
            <Card.Title className="mb-0">Login Details</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Form>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputUsername2">
                      Username{" "}
                      <span className="text-muted">(email address)</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="inputUsername2"
                      placeholder={user.email}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" onClick={(e) => handlePasswordReset(e)}>
                Send password reset email
              </Button>
            </Form>
          </Card.Body>
        </Tab.Pane>
      </Tab.Content>
    </Card>
  );
};

const EditProfile = () => (
  <React.Fragment>
    <Helmet title="Your Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        Your Profile
        <OffcanvasHelp
          id="edityourprofile"
          name="Edit Your Profile"
          scroll
          backdrop
        />
      </h1>
      <Tab.Container id="edit-profile-tabs" defaultActiveKey="#1">
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            <ProfileInfo />
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  </React.Fragment>
);

export default EditProfile;
