import React from "react";
import { Form, Alert } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "../DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  Slider,
  RadioTile,
  RadioTileGroup,
  useMediaQuery,
  Radio,
  RadioGroup,
} from "rsuite";
import { format } from "date-fns";
import { renderErrorMessages } from "./utils";

const InfoBox = ({ field }) => {
  return field.infoBox ? (
    <Alert variant="primary" className="alert-outline">
      <div className="alert-icon d-flex align-items-center">
        <FontAwesomeIcon icon={faQuestion} fixedWidth />
      </div>
      <div className="alert-message">{field.infoBox}</div>
    </Alert>
  ) : null;
};

const FormLabel = ({ field }) => (
  <Form.Label className={field.condition ? "fs-5" : "fs-4 text-dark"}>
    {field.condition ? (
      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
    ) : (
      ""
    )}
    {field.title || field.label}
  </Form.Label>
);

const TextField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group
      className={field.condition ? "ms-3" : ""}
      controlId={field.name}
    >
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Form.Control
        type="text"
        placeholder={field.placeholder}
        name={field.name}
        value={values[field.name] || ""}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value);
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: e.target.value || "",
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const PrefilledTextField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
  prefill,
}) => {
  React.useEffect(() => {
    setFieldValue(field.name, prefill);
    setFieldTouched(field.name, true, false);
    validateField(field.name)
      .then(() => {
        setMyFormData((prevData) => ({
          ...prevData,
          [field.name]: prefill || "",
        }));
      })
      .catch((error) => {});
  }, [
    prefill,
    setFieldValue,
    setFieldTouched,
    validateField,
    setMyFormData,
    field.name,
  ]);

  return (
    <div className="mb-4">
      <Form.Group
        className={field.condition ? "ms-3" : ""}
        controlId={field.name}
      >
        <FormLabel field={field} />
        <InfoBox field={field} />
        <Form.Control
          type="text"
          placeholder={field.placeholder}
          name={field.name}
          value={values[field.name] || prefill}
          disabled={true}
          onChange={(e) => {
            setFieldValue(field.name, e.target.value);
            setFieldTouched(field.name, true, false);
            validateField(field.name)
              .then(() => {
                setMyFormData((prevData) => ({
                  ...prevData,
                  [field.name]: e.target.value || "",
                  [field.changeClearsField]: null,
                }));
              })
              .catch((error) => {});
          }}
          onBlur={handleBlur}
        />
        {errors[field.name] && touched[field.name] && (
          <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
        )}
      </Form.Group>
    </div>
  );
};

const DynamicSelectField = ({
  field,
  dynamicSelectOptions,
  loadingDynamicSelect,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group
      className={field.condition ? "ms-3" : ""}
      controlId={field.name}
    >
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Select
        name={field.name}
        options={dynamicSelectOptions[field.name] || []}
        isLoading={loadingDynamicSelect}
        onChange={(option) => {
          setFieldValue(field.name, {
            value: option.value,
            label: option.label,
          });
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: {
                  value: option.value,
                  label: option.label,
                },
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        value={dynamicSelectOptions[field.name]?.find(
          (option) => values[field.name]?.value === option.value
        )}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const DynamicMultipleSelectField = ({
  field,
  dynamicSelectOptions,
  loadingDynamicSelect,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group
      className={field.condition ? "ms-3" : ""}
      controlId={field.name}
    >
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Select
        name={field.name}
        options={dynamicSelectOptions[field.name] || []}
        isLoading={loadingDynamicSelect}
        isMulti={true}
        onChange={(selectedOptions) => {
          const newValues = selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
          setFieldValue(field.name, newValues);
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: newValues,
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        value={values[field.name] || []}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const SelectField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group
      className={field.condition ? "ms-3" : ""}
      controlId={field.name}
    >
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Select
        name={field.name}
        options={field.options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onChange={(option) => {
          setFieldValue(field.name, {
            value: option.value,
            label: option.label,
          });
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: {
                  value: option.value,
                  label: option.label,
                },
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        value={field.options.find(
          (option) => values[field.name]?.value === option.value
        )}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const LazySelectField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group controlId={field.name}>
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Select
        name={field.name}
        options={field.options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onChange={(option) => {
          setFieldValue(field.name, {
            value: option.value,
            label: option.label,
          });
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: {
                  value: option.value,
                  label: option.label,
                },
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        value={field.options.find(
          (option) => values[field.name]?.value === option.value
        )}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const CheckboxField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group controlId={field.name}>
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Form.Check
        type="checkbox"
        name={field.name}
        id={field.name}
        label={field.label}
        checked={values[field.name]}
        onChange={(event) => {
          const { checked } = event.target;
          setFieldValue(field.name, checked);
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: checked,
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const SwitchField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
  handleBlur,
}) => (
  <div className="mb-4">
    <Form.Group controlId={field.name}>
      <FormLabel field={field} />
      <InfoBox field={field} />
      <Form.Check
        type="switch"
        name={field.name}
        label={field.label}
        id={field.name}
        checked={values[field.name]}
        onChange={(event) => {
          const { checked } = event.target;
          setFieldValue(field.name, checked);
          setFieldTouched(field.name, true, false);
          validateField(field.name)
            .then(() => {
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: checked,
                [field.changeClearsField]: null,
              }));
            })
            .catch((error) => {});
        }}
        onBlur={handleBlur}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const DateField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
}) => (
  <div className="mb-4">
    <Form.Group controlId={field.name}>
      <FormLabel field={field} />
      <InfoBox field={field} />
      <div className="d-flex justify-content-center">
        <DatePicker
          selectedDate={values[field.name]}
          setSelectedDate={(date) => {
            setFieldValue(field.name, date);
            setFieldTouched(field.name, true, false);
            validateField(field.name)
              .then(() => {
                setMyFormData((prevData) => ({
                  ...prevData,
                  [field.name]: date,
                  [field.changeClearsField]: null,
                }));
              })
              .catch((error) => {});
          }}
        />
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
      )}
    </Form.Group>
  </div>
);

const RangeField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  validateField,
  setMyFormData,
  errors,
  touched,
}) => {
  React.useEffect(() => {
    const defaultValue =
      values[field.name] || field.options.default || field.options.min;
    setFieldValue(field.name, defaultValue);
    setFieldTouched(field.name, true, false);
    validateField(field.name)
      .then(() => {
        setMyFormData((prevData) => ({
          ...prevData,
          [field.name]: defaultValue,
        }));
      })
      .catch((error) => {});
  }, [
    field.name,
    field.options.default,
    field.options.min,
    setFieldValue,
    setFieldTouched,
    validateField,
    setMyFormData,
    values,
  ]);

  return (
    <div className="mb-4">
      <Form.Group controlId={field.name}>
        <FormLabel field={field} />
        <InfoBox field={field} />
        <div className="my-3 pb-1" style={{ width: "90%", margin: "0px auto" }}>
          <Slider
            value={values[field.name]}
            step={field.options.step}
            graduated
            progress
            min={field.options.min}
            max={field.options.max}
            renderMark={(mark) => {
              if (field.options.marks.includes(mark)) {
                return (
                  <span>
                    {mark} {field.options.unit}
                  </span>
                );
              }
              return null;
            }}
            onChange={(value) => {
              setFieldValue(field.name, value);
              setFieldTouched(field.name, true, false);
              validateField(field.name)
                .then(() => {
                  setMyFormData((prevData) => ({
                    ...prevData,
                    [field.name]: value,
                    [field.changeClearsField]: null,
                  }));
                })
                .catch((error) => {});
            }}
          />
        </div>
        {errors[field.name] && touched[field.name] && (
          <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
        )}
      </Form.Group>
    </div>
  );
};

const RadioTileField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  setMyFormData,
  errors,
  touched,
}) => {
  const [isInline] = useMediaQuery("xl");
  return (
    <div className="mb-4">
      <Form.Group controlId={field.name}>
        <FormLabel field={field} />
        <InfoBox field={field} />
        <div className="my-3 mt-0 pb-1" style={{ margin: "0px auto" }}>
          <RadioTileGroup
            inline={isInline}
            aria-label="Radiotile"
            value={values[field.name]}
            onChange={(value) => {
              setFieldValue(field.name, value);
              setFieldTouched(field.name, true, false);
              setMyFormData((prevData) => ({
                ...prevData,
                [field.name]: value,
                [field.changeClearsField]: null,
              }));
            }}
          >
            {field.options.map((option) => (
              <RadioTile
                key={option.value}
                icon={option.icon}
                label={option.label}
                value={option.value}
                disabled={option.disabled}
              >
                {option.description}
              </RadioTile>
            ))}
          </RadioTileGroup>
        </div>
        {errors[field.name] && touched[field.name] && (
          <div className="mt-2">{renderErrorMessages(errors[field.name])}</div>
        )}
      </Form.Group>
    </div>
  );
};

const RadioGroupField = ({
  field,
  values,
  setFieldValue,
  setFieldTouched,
  setMyFormData,
  myFormData,
  errors,
  touched,
}) => (
  <div className="mb-4">
    <Form.Group controlId={field.name}>
      <FormLabel field={field} />
      <InfoBox field={field} />
      <div className="my-3 mt-0 pb-1" style={{ margin: "0px auto" }}>
        <RadioGroup
          inline={true}
          appearance="picker"
          aria-label="Radiogroup"
          value={values[field.name]}
          onChange={(value) => {
            setFieldValue(field.name, value);
            setFieldTouched(field.name, true, false);
            setMyFormData((prevData) => ({
              ...prevData,
              [field.name]: value,
              [field.changeClearsField]: null,
            }));
          }}
        >
          {field.options.map((option) => (
            <>
              <Radio
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </Radio>
            </>
          ))}
        </RadioGroup>
      </div>
    </Form.Group>
  </div>
);

const ConfirmationField = ({ myFormData, formConfig }) => (
  <div className="mb-4">
    {formConfig.steps.map((step, stepIndex) => {
      if (["review", "process"].includes(step.stepType)) {
        return null;
      }
      return (
        <div key={stepIndex}>
          <h4>{step.title}</h4>
          {step.fields.map((field, fieldIndex) => {
            if (
              myFormData.hasOwnProperty(field.name) &&
              myFormData[field.name] !== null &&
              (!field.condition || field.condition(myFormData) === true)
            ) {
              let value = myFormData[field.name];

              if (field.type === "date" && value) {
                value = format(value, "do LLLL yyyy");
              } else if (["switch", "checkbox"].includes(field.type)) {
                value = value ? "Yes" : "No";
              } else if (["select", "dynamicSelect"].includes(field.type)) {
                value = value?.label || "N/A";
              } else if (field.type === "dynamicMultiSelect") {
                value =
                  Array.isArray(value) && value.length > 0
                    ? value.map((val) => val.label || "N/A").join(", ")
                    : "N/A";
              }

              if (value && value !== "") {
                return (
                  <div key={fieldIndex}>
                    <h5 className="my-3 ms-3">
                      <span className="text-muted">{field.confirmLabel}:</span>{" "}
                      {value}
                    </h5>
                  </div>
                );
              }
            }
            return null;
          })}
        </div>
      );
    })}
  </div>
);

export {
  TextField,
  PrefilledTextField,
  DynamicSelectField,
  DynamicMultipleSelectField,
  SelectField,
  LazySelectField,
  CheckboxField,
  SwitchField,
  DateField,
  RangeField,
  RadioTileField,
  RadioGroupField,
  ConfirmationField,
};
