import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Formik, Form } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

import Stepper from "./Stepper";
import FormPage from "./FormPage";

import useAuth from "../../hooks/useAuth";

const MultiPageForm = ({
  config,
  openModals,
  toggle,
  setRefreshData,
  refreshData,
  prefillData = {},
  initialPage = 1,
}) => {
  const [page, setPage] = useState(initialPage);
  const [myFormData, setMyFormData] = useState(prefillData);

  const [executeAll, setExecuteAll] = useState(false);
  const { userAccessToken, user } = useAuth();
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  const [submissionStatus, setSubmissionStatus] = useState({
    isLoading: false,
    result: null,
    message: "",
    tasks: [],
  });

  useEffect(() => {
    setPage(initialPage);
    setMyFormData(prefillData);
  }, [initialPage, prefillData]);

  const updateTaskStatus = (
    taskName,
    status,
    message,
    payload,
    ctaLink = null
  ) => {
    setSubmissionStatus((prevStatus) => {
      let taskFound = false;
      const newTasks = prevStatus.tasks.map((task) => {
        if (task.name === taskName) {
          taskFound = true;
          return { ...task, status, message, payload, ctaLink };
        }
        return task;
      });

      if (!taskFound) {
        newTasks.push({ name: taskName, status, message, payload, ctaLink });
      }

      const allTasksCompleted = newTasks.every(
        (task) => task.status === "succeeded" || task.status === "failed"
      );

      if (allTasksCompleted) {
        const allSucceeded = newTasks.every(
          (task) => task.status === "succeeded"
        );
        const anyFailed = newTasks.some((task) => task.status === "failed");

        return {
          ...prevStatus,
          isLoading: false,
          result: allSucceeded ? "success" : anyFailed ? "partial" : "failure",
          message: allSucceeded
            ? "All tasks completed successfully."
            : anyFailed
            ? "Some tasks failed."
            : "All tasks have failed.",
          tasks: newTasks,
        };
      } else {
        return {
          ...prevStatus,
          isLoading: true,
          tasks: newTasks,
        };
      }
    });
  };

  const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleUserConfirmation = (executeAllTasks = false) => {
    setIsConfirmed(true);
    setExecuteAll(executeAllTasks);
    setIsConfirmationNeeded(false);
  };

  const handleSubmitClick = (values, formikBag) => {
    setPage(config.steps.length);
    setSubmissionStatus({
      isLoading: true,
      result: null,
      message: "",
      tasks: [],
    });

    const finalFormData = { ...myFormData, ...values };
    config
      .handleSubmit(finalFormData, {
        userAccessToken,
        updateTaskStatus,
        submissionStatus,
        setSubmissionStatus,
        setIsConfirmationNeeded,
        isConfirmed,
      })
      .then(() => {
        // setSubmissionStatus((prevStatus) => ({
        //   ...prevStatus,
        //   isLoading: false,
        //   result: "success",
        //   message: "Submission successful!",
        // }));
      })
      .catch((error) => {
        // setSubmissionStatus((prevStatus) => ({
        //   ...prevStatus,
        //   isLoading: false,
        //   result: "failure",
        //   message: "Submission failed. Please try again.",
        // }));
      })
      .finally(() => formikBag.setSubmitting(false));
  };

  const currentStepConfig = config.steps[page - 1];
  const isLastStep = page === config.steps.length - 1;
  const isSubmissionStep = page === config.steps.length;

  const handlePrev = () => {
    setPage(Math.max(1, page - 1));
  };
  const handleNext = (currentValues, formikBag) => {
    if (isLastStep) {
      formikBag.submitForm();
    } else {
      const updatedFormData = { ...myFormData, ...currentValues };
      setMyFormData(updatedFormData);
      setPage(Math.min(config.steps.length, page + 1));
    }
  };

  const getInitialValuesForStep = (step) => {
    const stepConfig = config.steps[step - 1];
    const initialValues = {};
    stepConfig.fields.forEach((field) => {
      const shouldDisplay = field.condtion ? field.condtion(myFormData) : true;
      if (shouldDisplay) {
        initialValues[field.name] = myFormData[field.name] || "";
      }
    });
    return initialValues;
  };

  useEffect(() => {}, [myFormData]);

  useEffect(() => {
    if (isConfirmed) {
      const helpers = {
        updateTaskStatus,
        setSubmissionStatus,
        userAccessToken,
        user,
        myFormData,
        selectedLandlord,
      };

      if (executeAll) {
        config.executeAllTasks({
          helpers,
          submissionStatus,
          setSubmissionStatus,
        });
      } else {
        const nextTaskIndex = submissionStatus.tasks.findIndex(
          (task) => task.status === "pending"
        );
        if (nextTaskIndex !== -1) {
          config.executeNextTask({
            helpers,
            submissionStatus,
            taskIndex: nextTaskIndex,
            setSubmissionStatus,
          });
        }
      }
      setIsConfirmed(false);
    }
  }, [
    user,
    selectedLandlord,
    isConfirmed,
    executeAll,
    config,
    submissionStatus,
    userAccessToken,
    myFormData,
  ]);

  return (
    <Modal
      show={openModals[config.name]}
      onHide={() => {
        toggle(config.name);
        setPage(1);
        setMyFormData({});
        setRefreshData(!refreshData);
        setSubmissionStatus({
          result: null,
          message: "",
          tasks: [],
          isLoading: false,
        });
      }}
      size="xl"
      centered
    >
      <Modal.Header closeButton style={{ border: "0" }}>
        <Modal.Title className="ms-4 mt-1 text-muted fs-5">
          {config.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="p-3">
          <Col md="4" className="px-3">
            <Stepper
              steps={config.steps}
              currentPage={page}
              submissionStatus={submissionStatus}
              isSubmissionStep={isSubmissionStep}
            />
          </Col>
          <Col md="8" className="px-6">
            <div className="d-flex flex-row-fluid justify-content-center">
              <Formik
                key={page}
                initialValues={getInitialValuesForStep(page, config)}
                validationSchema={currentStepConfig.validationSchema}
                onSubmit={(values, formikBag) => {
                  if (isLastStep) {
                    handleSubmitClick(values, formikBag);
                  } else {
                    handleNext(values);
                  }
                }}
                enableReinitialize={true}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  values,
                  validateField,
                  setFieldTouched,
                }) => (
                  <Form className="pb-5 w-100">
                    <FormPage
                      formConfig={config}
                      stepConfig={currentStepConfig}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      validateField={validateField}
                      setFieldTouched={setFieldTouched}
                      myFormData={myFormData}
                      setMyFormData={setMyFormData}
                      isSubmissionStep={isSubmissionStep}
                      submissionStatus={submissionStatus}
                      isConfirmationNeeded={isConfirmationNeeded}
                      handleUserConfirmation={handleUserConfirmation}
                      userAccessToken={userAccessToken}
                      userObject={user}
                      selectedLandlord={selectedLandlord}
                    />
                    <div className="d-flex justify-content-between pt-10">
                      <Button
                        onClick={handlePrev}
                        className="btn btn-lg btn-light"
                        disabled={page === 1}
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeftLong}
                          className="me-1"
                        />{" "}
                        Previous
                      </Button>
                      {isSubmissionStep ? (
                        <Button
                          onClick={() => {
                            toggle(config.name);
                            setPage(1);
                            setMyFormData({});
                            setRefreshData(!refreshData);
                          }}
                          className="btn btn-lg btn-primary"
                        >
                          Close
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-lg btn-primary"
                          type="submit"
                        >
                          {isLastStep
                            ? config.submitButtonText || "Submit"
                            : "Next"}{" "}
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="ms-1"
                          />
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MultiPageForm;
