import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import Showdown from "showdown";

import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
  Breadcrumb,
  Badge,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";

import ReactQuill from "react-quill";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye,
  faEyeSlash,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";

import GetSupport from "../../components/GetSupport";
import IsAllowed from "../../components/IsAllowed";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";
import NotyfContext from "../../contexts/NotyfContext";

const TicketThread = () => {
  const { userAccessToken, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [ticketNotes, setTicketNotes] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  function handleRefresh(e) {
    setRefresh(!refresh);
  }
  const { ticketid } = useParams();
  const markdownconverter = new Showdown.Converter({
    parseImgDimensions: true,
  });

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let axiosDetailsUrl = apiConfig.ticketsApiUrl + "tickets/" + ticketid;
    let axiosNotesUrl =
      apiConfig.ticketsApiUrl +
      "tickets/" +
      ticketid +
      "/allnotes/?includeInternalNotes=true";
    axios
      .get(axiosDetailsUrl, axiosConfig)
      .then((response) => {
        setTicketDetails(response.data);
      })
      .catch((error) => {});
    axios
      .get(axiosNotesUrl, axiosConfig)
      .then((response) => {
        setTicketNotes(
          response.data.sort((a, b) => {
            return new Date(a._info.sortByDate) - new Date(b._info.sortByDate);
          })
        );
      })
      .catch((error) => {});
  }, [bearerToken, ticketid, refresh]);

  const [noteText, setNoteText] = useState("");
  function handleNoteText(e) {
    setNoteText(e);
  }
  const [isPrivateNote, setIsPrivateNote] = useState(false);
  function handleIsPrivateNote(e) {
    setIsPrivateNote(!isPrivateNote);
  }

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  //const navigate = useNavigate();

  const handleCreateTicketNoteSubmit = (event) => {
    event.preventDefault();
    const createTicketNoteBody = {
      ticketId: ticketDetails?.id,
      text: `**Note added via Gateway by ${user.displayName} (${user.email}):**\n\n${noteText}`,
      detailDescriptionFlag: true,
      internalAnalysisFlag: isPrivateNote,
      customerUpdatedFlag: true,
      processNotifications: true,
      contact: {
        name: user.name,
      },
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(
        apiConfig.ticketsApiUrl + "tickets/" + ticketDetails?.id + "/notes",
        createTicketNoteBody,
        axiosConfig
      )
      .then((response) => {
        notyf.open({
          type,
          message: "Your ticket note was added successfully.",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        //setTimeout(() => window.location.reload(false), 1000);
        handleRefresh();
        handleNoteText("");
      })
      .catch((error) => {
        notyf.open({
          type: "warning",
          message:
            "There was an error adding your ticket note." +
            error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          #{ticketDetails.id} - {ticketDetails.summary}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <h4 className="px-2">Original request</h4>
        <hr />
        <Card className="ticket-original">
          <Card.Header className="ticket-original pb-0">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-right" className="mt-n1 mx-1">
                  Visible to all
                </Tooltip>
              }
            >
              <div className="card-actions float-end">
                <FontAwesomeIcon icon={faEye} />
              </div>
            </OverlayTrigger>
            <h5>
              {ticketNotes[0]?.member?.name || ticketNotes[0]?.contact?.name}
            </h5>
            <span className="text-muted text-sm">
              {new Date(ticketNotes[0]?._info?.sortByDate).toLocaleString(
                "en-GB"
              )}
            </span>
          </Card.Header>
          <Card.Body>
            <div
              className="ticket_note"
              dangerouslySetInnerHTML={{
                __html: markdownconverter.makeHtml(ticketNotes[0]?.text),
              }}
            />
          </Card.Body>
        </Card>
        <h4 className="px-2">Ticket thread</h4>
        <hr />
        {ticketNotes.slice(1).map((ticketNote) => (
          <Card
            className={
              ticketNote?.internalAnalysisFlag
                ? "ticket-private"
                : "ticket-original"
            }
          >
            <Card.Header
              className={
                ticketNote?.internalAnalysisFlag
                  ? "ticket-private pb-0"
                  : "ticket-original pb-0"
              }
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right" className="mt-n1 mx-1">
                    {ticketNote?.internalAnalysisFlag
                      ? "Visibile internally"
                      : "Visible to all"}
                  </Tooltip>
                }
              >
                <div className="card-actions float-end">
                  <FontAwesomeIcon
                    icon={ticketNote?.internalAnalysisFlag ? faEyeSlash : faEye}
                  />
                </div>
              </OverlayTrigger>
              <h5>{ticketNote?.member?.name || ticketNote?.contact?.name}</h5>
              <span className="text-muted text-sm">
                {new Date(ticketNote?._info?.sortByDate).toLocaleString(
                  "en-GB"
                )}
              </span>
            </Card.Header>
            <Card.Body>
              <div
                className="ticket_note"
                dangerouslySetInnerHTML={{
                  __html: markdownconverter.makeHtml(ticketNote?.text),
                }}
              />
            </Card.Body>
          </Card>
        ))}
        <h4 className="px-2">Add a reply</h4>
        <hr />
        <Form onSubmit={handleCreateTicketNoteSubmit}>
          <Form.Group className="mb-3">
            <ReactQuill
              id="replytext"
              placeholder="Type your ticket response here"
              value={noteText}
              onChange={handleNoteText}
            />
            <Button
              variant="primary"
              className="mt-2 mb-3 btn-lg float-end"
              type="submit"
            >
              <FontAwesomeIcon icon={faCommentAlt} /> Submit Reply
            </Button>
            <IsAllowed to="edit:tickets:private">
              <Form.Check
                type="switch"
                id="exampleCustomSwitch"
                name="customSwitch"
                label="Private note"
                className="float-end m-3"
                checked={isPrivateNote}
                onChange={handleIsPrivateNote}
              />
            </IsAllowed>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

const TicketDetails = () => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [ticketDetails, setTicketDetails] = useState([]);
  const [connectedBuildingField, setConnectedBuildingField] = useState([]);
  const [connectedLandlordField, setConnectedLandlordField] = useState([]);
  const { ticketid } = useParams();

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let axiosDetailsUrl = apiConfig.ticketsApiUrl + "tickets/" + ticketid;
    axios
      .get(axiosDetailsUrl, axiosConfig)
      .then((response) => {
        setTicketDetails(response.data);
        const connectedBuildingField = response.data.customFields?.filter(
          (field) => {
            return field.caption === "Connected Building";
          }
        );
        setConnectedBuildingField(connectedBuildingField);
        const connectedLandlordField = response.data.customFields?.filter(
          (field) => {
            return field.caption === "Connected Landlord";
          }
        );
        setConnectedLandlordField(connectedLandlordField);
      })
      .catch((error) => {});
  }, [bearerToken, ticketid]);

  /*React.useEffect(() => {
    
    const connectedBuildingField = ticketDetails?.customFields?.filter(
      (field) => {
        return field.caption === "Connected Building";
      }
    );
    setConnectedBuildingField(connectedBuildingField);
  }, [ticketDetails]);*/
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Ticket Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm">
          <tbody>
            <tr>
              <th>Customer Name</th>
              <td>{ticketDetails?.company?.name}</td>
            </tr>
            <tr>
              <th>Account Number</th>
              <td>{ticketDetails?.company?.identifier}</td>
            </tr>
            <tr>
              <th>Requester</th>
              <td>{ticketDetails?.contactName}</td>
            </tr>
            <tr>
              <th>Created at</th>
              <td>
                {new Date(ticketDetails?._info?.dateEntered).toLocaleString(
                  "en-GB"
                )}
              </td>
            </tr>
            <tr>
              <th>Last update</th>
              <td>
                {new Date(ticketDetails?._info?.lastUpdated).toLocaleString(
                  "en-GB"
                )}
              </td>
            </tr>
            <tr>
              <th>Associated Building</th>
              <td>{connectedBuildingField[0]?.value || "--"}</td>
            </tr>
            <tr>
              <th>Associated Landlord</th>
              <td>{connectedLandlordField[0]?.value || "--"}</td>
            </tr>
            <IsAllowed to="view:tickets:details">
              <tr>
                <th>Assigned To</th>
                <td>{ticketDetails?.team?.name}</td>
              </tr>
              <tr>
                <th>Ticket Status</th>
                <td>
                  <Badge bg="info">{ticketDetails?.status?.name}</Badge>
                </td>
              </tr>
              <tr>
                <th>Ticket Priority</th>
                <td>
                  <Badge bg="danger">{ticketDetails?.priority?.name}</Badge>
                </td>
              </tr>
            </IsAllowed>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const ViewTicket = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet title="Support Tickets" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-2">
          <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/tickets/list")}>
            Support Tickets
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Viewing Ticket</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Viewing Ticket
          <OffcanvasHelp
            id="viewingticket"
            name="Viewing Ticket"
            scroll
            backdrop
          />
        </h1>
        <IsAllowed to="create:tickets">
          <Link to="/tickets/new">
            <Button variant="primary" className="mt-n1 mb-3">
              <FontAwesomeIcon icon={faPlus} /> New Ticket
            </Button>
          </Link>
        </IsAllowed>
        <Row>
          <Col xl="8">
            <TicketThread />
          </Col>
          <Col xl="4">
            <TicketDetails />
            <GetSupport type="support" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ViewTicket;
