import * as Yup from "yup";

import axios from "axios";
import { apiConfig } from "../../../config";

const taskHandlers = {
  "Register order in Wavenet's CRM": (myFormData, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.hubspotApiUrl}orders`, myFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({
            message: "Order registered successfully",
            data: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  "Generate DealHub Quotation URL": (
    previousTask,
    token,
    { myFormData, updateHandler, task, user, selectedLandlord }
  ) => {
    return new Promise((resolve, reject) => {
      resolve({
        message: "let's go",
        ctaLink: {
          link: `/customers/orders`,
          label: "View Orders",
        },
      });
    });
  },
};

const formConfig = {
  name: "customerOrder",
  title: "CREATE NEW CUSTOMER ORDER",
  steps: [
    {
      title: "New or Existing Customer",
      description: "Choose what type of customer this order is for",
      longDescription:
        "Choose an existing customer or a registered prospect for this order. If you need to register a new customer, please use the customer registration form.",
      stepType: "input",
      fields: [
        {
          name: "registered_customer",
          label: "Are you ordering for an existing Wavenet customer?",
          confirmLabel: "Existing Wavenet Customer",
          type: "radiotile",
          options: [
            {
              label: "Existing Customer",
              description:
                "I am ordering for an existing Wavenet customer with an XLW account number.",
              value: "true",
              name: "registered_customer",
            },
            {
              label: "New Customer",
              description:
                "I am ordering for a new Wavenet prospect that has been registered in Wavenet's CRM.",
              value: "false",
              name: "registered_customer",
            },
          ],
          validation: Yup.string().required("Make a selection"),
        },
        {
          name: "customer",
          label: "Find Customer",
          confirmLabel: "Customer",
          type: "dynamicSelect",
          options: [],
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.symbillApiUrl}newsymbillapi/byCtidList/${selectedLandlord.landlordid}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.map((customer) => ({
                    label:
                      customer.companyName +
                      " (" +
                      customer.accountNumber +
                      ")",
                    value: customer.accountNumber,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: Yup.string().required("Customer is required"),
          condition: (formValues) => formValues.registered_customer === "true",
        },
        {
          name: "prospect",
          label: "Prospect Customer Company Name",
          confirmLabel: "Prospect Search",
          type: "text",
          validation: Yup.string().required("Customer is required"),
          condition: (formValues) => formValues.registered_customer === "false",
        },
      ],
      validationSchema: Yup.object().shape({
        registered_customer: Yup.string().required(
          "Please select an option from above"
        ),
        customer: Yup.string().when("registered_customer", {
          is: "true",
          then: () =>
            Yup.object().shape({
              value: Yup.string().required("Customer selection is required"),
            }),
          otherwise: () => Yup.object().nullable(),
        }),
        prospect: Yup.string().when("registered_customer", {
          is: "false",
          then: () =>
            Yup.string().required("Prospect company name is required"),
          otherwise: () => Yup.string().nullable(),
        }),
      }),
    },
    {
      stepType: "externalQuery__hubspot_order",
      title: "Select Customer",
      description: "Choose the customer record to raise an order against",
      longDescription:
        "Search Wavenet's CRM for the correct record to raise the new order against.",
      fields: [
        {
          name: "hubspot_id",
          label: "Company Hubspot ID",
          confirmLabel: "Company Hubspot ID",
          type: "radiotile",
        },
      ],
      validationSchema: Yup.object().shape({
        hubspot_id: Yup.string()
          .required("You must select a Hubspot record to proceed.")
          .nullable(false),
      }),
      query: {
        url: `/api/customers/exists`,
      },
      stepPermission: "view:customers:all",
      queryHandler: (
        formData,
        userAccessToken,
        setLoadingExternalQuery,
        setExternalQueryResults
      ) => {
        setLoadingExternalQuery(true);
        let query = "?";
        if (formData.registered_customer === "false") {
          query += `company_name=${formData.prospect}`;
        } else {
          query += `account_number=${formData.customer.value}`;
        }
        axios
          .get(
            `${apiConfig.hubspotApiUrl}companies/possiblecompanies${query}`,
            {
              headers: {
                Authorization: `Bearer ${userAccessToken}`,
              },
            }
          )
          .then((response) => {
            setLoadingExternalQuery(false);
            setExternalQueryResults(response.data.companies);
          })
          .catch((error) => {
            setLoadingExternalQuery(false);
            setExternalQueryResults([]);
          });
        return null;
      },
    },
    {
      title: "Building & Unit",
      description: "Choose a building and room",
      stepType: "input",
      fields: [
        {
          name: "building",
          label: "Select Building",
          confirmLabel: "Building",
          type: "dynamicSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.connectedApiUrl}buildings/withoperator?page_size=1000&building_operator=${selectedLandlord.landlordid}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.buildings.items
                    .filter((b) => b.status === "Live Building")
                    .map((building) => ({
                      label: building.building_name,
                      value: building.id,
                    }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: Yup.string().required("Building is required"),
          changeClearsField: "unit",
        },
        {
          name: "unit",
          label: "Select Unit",
          confirmLabel: "Unit",
          type: "dynamicMultiSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord,
            myFormData
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.connectedApiUrl}rooms/?page_size=1000&building_id=${myFormData?.building?.value}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.rooms.items.map((room) => ({
                    label: room.room_number,
                    value: room.id,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          queryHandlerDependencies: ["building.value"],
          validation: Yup.array()
            .of(
              Yup.object().shape({
                value: Yup.string().required("Unit selection is required"),
              })
            )
            .min(1, "At least one unit must be selected"),
          condition: (formValues) => !!formValues.building.value,
        },
      ],
      validationSchema: Yup.object().shape({
        building: Yup.object().shape({
          value: Yup.string().required("Building selection is required"),
        }),
        unit: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string().required("Unit selection is required"),
            })
          )
          .min(1, "At least one unit must be selected"),
      }),
    },
    {
      title: "Move-In Date",
      description: "Choose the move-in date",
      longDescription:
        "Tell us what date the customer will be moving into this unit. We'll use this to schedule the service provisioning.",
      stepType: "input",
      fields: [
        {
          name: "moveInDate",
          label: "Move-In Date",
          confirmLabel: "Move-In Date",
          type: "date",
          validation: null,
        },
      ],
      validationSchema: Yup.object().shape({
        moveInDate: Yup.date().required("Move-in date is required"),
      }),
    },
    {
      title: "Commercial Model",
      description: "Select the commercial model for this order",
      longDescription:
        "Select the commercial model that has been agreed with the prospective tenant for this order.",
      stepType: "input",
      fields: [
        {
          name: "commercialModel",
          label: "Available Commercial Models",
          confirmLabel: "Commercial Model",
          type: "dynamicSelect",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord,
            myFormData
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.hubspotApiUrl}orders/get_pricing_models/${selectedLandlord.landlordid}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.pricingModels.map((model) => ({
                    label: model.model_name,
                    value: model.model_sku,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
          validation: null,
        },
        {
          name: "minimumTerm",
          label: "Contract Minimum Term",
          infoBox:
            "Select the minimum term for this contract. The default is 36 months. Changes to the minimum term that do not align with the customer lease may be rejected during order processing.",
          confirmLabel: "Contract Minimum Term",
          type: "range",
          options: {
            step: 12,
            min: 12,
            max: 36,
            unit: "Months",
            marks: [12, 24, 36],
            default: 36,
          },
        },
      ],
      validationSchema: Yup.object().shape({
        commercialModel: Yup.object().shape({
          value: Yup.string().required(
            "Commercial model selection is required"
          ),
        }),
        minimumTerm: Yup.number().required("Minimum term is required"),
      }),
    },
    {
      title: "Network Services Products",
      description: "Specify products to be included in this order",
      longDescription: "Add network services products to the order below.",
      stepType: "input",
      fields: [
        // {
        //   name: "OSIA",
        //   title: "Open Secure Internet Access",
        //   label: "Choose bandwidth allowance for customer",
        //   confirmLabel: "Bandwidth allowance",
        //   type: "range",
        //   options: {
        //     step: 1,
        //     min: 1,
        //     max: 100,
        //     unit: "Workstations",
        //     marks: [1, 50, 100],
        //   },
        //   validation: null,
        // },
        {
          name: "networkServiceSelection",
          title: "Choose a network service",
          label: "Choose a network service",
          confirmLabel: "Network Service",
          type: "select",
          options: [
            {
              label: "OSIA",
              value: "OSIA",
              name: "networkServiceSelection",
            },
          ],
          validation: null,
        },
        // {
        //   name: "vlanSelection",
        //   title: "Customer VLAN Type",
        //   label: "Customer VLAN selection",
        //   confirmLabel: "VLAN for customer network",
        //   type: "select",
        //   options: [
        //     {
        //       label: "Normal",
        //       value: "Normal",
        //       name: "vlanSelection",
        //     },
        //     {
        //       label: "X-Connect",
        //       value: "XConnect",
        //       name: "vlanSelection",
        //     },
        //   ],
        //   validation: null,
        //   condition: (formValues) =>
        //     formValues.networkServiceSelection.value === "OSIA",
        // },
        {
          name: "staticIpAddresses",
          title: "Public Static IP Addresses",
          label: "Customer requires public static IPs",
          confirmLabel: "Static IPs Required",
          // infoBox:
          //   "Select this option if you want to automatically provision the customer IT network for the unit.",
          type: "switch",
          validation: null,
          condition: (formValues) =>
            formValues.networkServiceSelection.value === "OSIA",
          changeClearsField: "numberStaticIps",
        },
        {
          name: "numberStaticIps",
          title: "Number of static IP addresses",
          label: "Number of static IP addresses",
          // infoBox:
          //   "If the customer needs more than 8 static IP addresses, please contact sales.",
          confirmLabel: "Number of static IP addresses",
          type: "range",
          options: {
            step: 1,
            min: 0,
            max: 8,
            unit: "",
            marks: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          },
          validation: null,
          condition: (formValues) => formValues.staticIpAddresses === true,
        },
        // {
        //   name: "provisionWifiUsers",
        //   title: "Wireless Network User Setup",
        //   infoBox:
        //     "Select this option if you want to automatically create Wi-Fi credentials for this customer's key contacts and send the subsequent onboarding email.",
        //   label: "Provision Wi-Fi Credentials",
        //   confirmLabel: "Provision wireless network",
        //   type: "switch",
        //   validation: null,
        // },
        // {
        //   name: "sendWifiCredentials",
        //   title: "Credential delivery",
        //   label: "Send wi-fi onboarding email to customer?",
        //   confirmLabel: "Send credentials to customer",
        //   type: "checkbox",
        //   validation: null,
        //   condition: (formValues) => formValues.provisionWifiUsers === true,
        // },
      ],
      validationSchema: null,
    },
    {
      title: "Order Approval",
      description: "Choose a contact to receive the order approval link",
      longDescription:
        "Select a contact from the customer's company to receive the order approval link.",
      stepType: "input",
      fields: [
        {
          name: "orderApprovalContactType",
          label: "Order Approval Contact Type",
          confirmLabel: "Order Approval Contact Type",
          // infoBox:
          //   "Select the type of contact to receive the order approval link",
          type: "radiogroup",
          options: [
            {
              label: "Company Contact",
              value: "hubspot",
              name: "order_approval_contact_type",
            },
            // {
            //   label: "Me",
            //   value: "me",
            //   name: "order_approval_contact_type",
            // },
            // {
            //   label: "Other",
            //   value: "other",
            //   name: "order_approval_contact_type",
            // },
          ],
          changeClearsField: "orderApprovalContact",
        },
        {
          name: "orderApprovalContact",
          label: "Order Approval Contact (hubspot)",
          confirmLabel: "Order Approval Contact (hubspot)",
          type: "dynamicSelect",
          condition: (formValues) =>
            formValues.orderApprovalContactType === "hubspot",
          queryHandler: (
            userAccessToken,
            setLoadingExternalQuery,
            setExternalQueryResults,
            selectedLandlord,
            myFormData
          ) => {
            setLoadingExternalQuery(true);
            axios
              .get(
                `${apiConfig.hubspotApiUrl}contacts/hubspotcompany/${myFormData.hubspot_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                setExternalQueryResults(
                  response.data.map((contact) => ({
                    label: `${contact.name} (${contact.email})`,
                    value: contact.hubspotId,
                  }))
                );
                setLoadingExternalQuery(false);
              })
              .catch((error) => {
                setExternalQueryResults([]);
                setLoadingExternalQuery(false);
              });
          },
        },
        {
          name: "orderApprovalContact",
          label: "Order Approval Contact (other)",
          confirmLabel: "Order Approval Contact (other)",
          type: "text",
          placeholder: "Provide a valid email address",
          condition: (formValues) =>
            formValues.orderApprovalContactType === "other",
        },
        {
          name: "orderApprovalContact",
          label: "Order Approval Contact (me)",
          confirmLabel: "Order Approval Contact (me)",
          type: "prefilledtext",
          prefill: "email",
          placeholder: "Provide a valid email address",
          condition: (formValues) =>
            formValues.orderApprovalContactType === "me",
        },
      ],
      validationSchema: Yup.object().shape({
        orderApprovalContactType: Yup.string().required(
          "Please select an option from above"
        ),
        orderApprovalContact: Yup.mixed().when("orderApprovalContactType", {
          is: "hubspot",
          then: () =>
            Yup.object({
              value: Yup.string().required(
                "Order approval contact selection is required"
              ),
            }).required("Order approval contact is required"),
          otherwise: () =>
            Yup.mixed().when("orderApprovalContactType", {
              is: (type) => type === "other" || type === "me",
              then: () =>
                Yup.string()
                  .email("Please provide a valid email address")
                  .required("Order approval contact email is required"),
              otherwise: () => Yup.mixed().nullable(),
            }),
        }),
      }),
    },
    // {
    //   title: "Calculate Order Pricing",
    //   confirmationStep: true,
    //   description: "Calculate pricing based on the order details",
    //   stepType: "review",
    //   fields: [
    //     {
    //       name: "confirm",
    //       label: "Confirm that the below details are correct",
    //       type: "confirm",
    //       validation: null,
    //     },
    //   ],
    //   validationSchema: null,
    // },
    // {
    //   title: "Calculate Order Pricing",
    //   confirmationStep: true,
    //   description: "Calculate pricing based on the order details",
    //   longDescription:
    //     "Calculating the pricing summary based on the order details.",
    //   stepType: "externalQuery__pricing",
    //   fields: [],
    //   queryHandler: (
    //     formData,
    //     userAccessToken,
    //     setLoadingExternalQuery,
    //     setExternalQueryResults,
    //     setExternalQueryError,
    //     setMyFormData
    //   ) => {
    //     setLoadingExternalQuery(true);

    //     axios
    //       .post(`${apiConfig.hubspotApiUrl}orders/price_summary`, formData, {
    //         headers: {
    //           Authorization: `Bearer ${userAccessToken}`,
    //         },
    //       })
    //       .then((response) => {
    //         setMyFormData({
    //           ...formData,
    //           calculatedPricing: response.data.pricing,
    //         });
    //         setExternalQueryError("");
    //         setExternalQueryResults(response.data.pricing.lineItems);
    //         setLoadingExternalQuery(false);
    //       })
    //       .catch((error) => {
    //         setMyFormData({
    //           ...formData,
    //           calculatedPricing: null,
    //         });
    //         setExternalQueryResults([]);
    //         setExternalQueryError(
    //           error?.response?.data?.error
    //             ? error?.response?.data?.error
    //             : JSON.stringify(error)
    //         );
    //         setLoadingExternalQuery(false);
    //       });
    //     return null;
    //   },
    //   validationSchema: null,
    // },

    {
      stepType: "review",
      title: "Confirm Details",
      confirmationStep: true,
      description: "Review details and create tenancy",
      fields: [
        {
          name: "confirm",
          label: "Confirm that the below details are correct",
          type: "confirm",
          validation: null,
        },
      ],
      validationSchema: null,
    },
    {
      title: "Finish",
      description: "Create the order in Wavenet's systems",
      stepType: "process",
      confirmationStep: true,
      fields: [],
    },
  ],
  submitButtonText: "Place Order",
  handleSubmit: async (
    formData,
    {
      setSubmissionStatus,
      userAccessToken,
      updateTaskStatus,
      setIsConfirmationNeeded,
    }
  ) => {
    const tasks = [
      {
        name: "Register order in Wavenet's CRM",
        status: "pending",
        description: "Register the order in Wavenet's CRM as a deal record.",
        continueText: "Continue",
      },
      {
        name: "Generate DealHub Quotation URL",
        status: "pending",
        description:
          "Generate a quotation in DealHub for the customer and generate a pre-authenticated redirection URL.",
        continueText: "Continue",
      },
    ];

    setIsConfirmationNeeded(true);

    setSubmissionStatus({
      isLoading: true,
      tasks: tasks.map((task) => ({ ...task, status: task.status })),
    });

    const firstTask = tasks[0];
    const handler = taskHandlers[firstTask.name];
    updateTaskStatus(firstTask.name, "loading", null, "");

    try {
      const result = await handler(formData, userAccessToken);
      updateTaskStatus(
        firstTask.name,
        "succeeded",
        result.message,
        result.payload,
        result.ctaLink
      );
    } catch (error) {
      updateTaskStatus(
        firstTask.name,
        "failed",
        error.message,
        error.payload,
        error.ctaLink
      );
    }
  },
  executeNextTask: async ({
    helpers,
    submissionStatus,
    taskIndex,
    setSubmissionStatus,
  }) => {
    const {
      updateTaskStatus,
      userAccessToken,
      myFormData,
      user,
      selectedLandlord,
    } = helpers;
    const task = submissionStatus.tasks[taskIndex];
    const previousTask = submissionStatus.tasks[taskIndex - 1];
    const handler = taskHandlers[task.name];
    updateTaskStatus(task.name, "loading", null, "");
    try {
      const result = await handler(previousTask.payload, userAccessToken, {
        task: task,
        updateHandler: updateTaskStatus,
        submissionStatusHandler: setSubmissionStatus,
        submissionStatus: submissionStatus,
        myFormData: myFormData,
        user: user,
        selectedLandlord: selectedLandlord,
      });
      updateTaskStatus(
        task.name,
        "succeeded",
        result.message,
        result.payload,
        result.ctaLink
      );
    } catch (error) {
      updateTaskStatus(
        task.name,
        "failed",
        error.message,
        error.payload,
        error.ctaLink
      );
    }
  },
  executeAllTasks: async ({
    helpers,
    submissionStatus,
    setSubmissionStatus,
  }) => {
    for (
      let i = submissionStatus.tasks.findIndex(
        (task) => task.status === "pending"
      );
      i < submissionStatus.tasks.length;
      i++
    ) {
      await formConfig.executeNextTask({
        helpers,
        submissionStatus,
        taskIndex: i,
        setSubmissionStatus,
      });
    }
  },
};

export default formConfig;
