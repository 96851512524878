import React, { useState, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../config";
import NotyfContext from "../../../contexts/NotyfContext";

const AddNewSSIDModal = ({
  openModals,
  toggle,
  landlordOptions,
  dispatch,
  setRefreshData,
}) => {
  const notyf = useContext(NotyfContext);

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const [ssidName, setSsidName] = useState("");
  const [landlordId, setLandlordId] = useState(null);
  const [enabled, setEnabled] = useState(0);
  const [accountsPerWorkstation, setAccountsPerWorkstation] = useState(1);
  const [simultaenousDevices, setSimultaneousDevices] = useState(1);
  const [validated, setValidated] = useState(false);

  const resetFormFields = () => {
    setSsidName("");
    setLandlordId(null);
    setEnabled(0);
    setAccountsPerWorkstation(1);
    setSimultaneousDevices(1);
    setValidated(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const createSSIDSettingsBody = [
      {
        name: "accounts_per_workstation",
        value: accountsPerWorkstation,
      },
      {
        name: "simultaneous_devices",
        value: simultaenousDevices,
      },
    ];

    const createSSIDBody = {
      ssid: ssidName,
      building_operator_id: landlordId,
      enabled: enabled,
      settings: createSSIDSettingsBody,
    };

    try {
      const response = await axios.post(
        apiConfig.credentialsApiUrl + "ssids/",
        createSSIDBody,
        { headers: { Authorization: bearerToken } }
      );
      if (response.status === 201) {
        notyf.open({
          type: "success",
          message: response.data.message,
          duration: 3000,
          ripple: true,
          dismissible: true,
          position: { x: "right", y: "top" },
        });
        toggle("addNewSSID");
        dispatch(setRefreshData());
        setTimeout(() => dispatch(setRefreshData()), 2000);
      }
    } catch (error) {
      notyf.open({
        type: "warning",
        message: error.response?.data?.error || "An error occurred.",
        duration: 3000,
        ripple: true,
        dismissible: true,
        position: { x: "right", y: "top" },
      });
    } finally {
      resetFormFields();
    }
  };

  return (
    <Modal
      show={openModals["addNewSSID"]}
      onHide={() => {
        toggle("addNewSSID");
        resetFormFields();
      }}
      centered
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <b>Admin Function:</b> Add New SSID
        </Modal.Header>
        <Modal.Body className="m-3">
          <Form.Group className="mb-3">
            <Form.Label>Landlord</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={landlordOptions}
              onChange={(e) => setLandlordId(e.value)}
              value={
                landlordId
                  ? landlordOptions.find((o) => o.value === landlordId)
                  : null
              }
            />
          </Form.Group>
          <>
            <hr />
            <Form.Group className="mb-3">
              <Form.Label>SSID Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="SSID Name"
                value={ssidName}
                onChange={(e) => setSsidName(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                SSID name is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Accounts Per Workstation</Form.Label>
              <Form.Control
                type="number"
                placeholder="1"
                onChange={(e) => {
                  setAccountsPerWorkstation(parseInt(e.target.value));
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Value is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Simultaneous Devices</Form.Label>
              <Form.Control
                type="number"
                placeholder="1"
                onChange={(e) => {
                  setSimultaneousDevices(parseInt(e.target.value));
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Value is required.
              </Form.Control.Feedback>
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle("addNewSSID")}>
            Cancel
          </Button>
          <Button type="submit" variant="info">
            Create SSID
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddNewSSIDModal;
