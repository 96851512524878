import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import GetSupport from "../../components/GetSupport";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";

import useAuth from "../../hooks/useAuth";
import usePermissions from "../../hooks/usePermissions";

import NotyfContext from "../../contexts/NotyfContext";

import LongDataLoader from "../../components/LongDataLoader";

import { downloadInvoice } from "../../utils/downloadInvoice";
import { apiConfig } from "../../config";

import { useSelector, useDispatch } from "react-redux";

const InvoicesList = () => {
  const notyf = useContext(NotyfContext);
  const notificationConfig = useState({
    type: "success",
    duration: "30000",
    ripple: true,
    dismissible: true,
    positionX: "right",
    positionY: "top",
  });

  const { duration, ripple, dismissible, positionX, positionY } =
    notificationConfig;

  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const selectedAccount = useSelector((state) => state.accountswitcher);
  const { userAccessToken, isAuthenticated } = useAuth();
  const [invoicesData, setInvoicesData] = useState([]);
  const dispatch = useDispatch();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const invoicesTableColumns = [
    {
      Header: "Invoice #",
      accessor: "invoiceref",
      Cell: (e) => (
        <div className="text-center">
          {e.row.original.pdf_available !== false ? (
            <Link
              to="#"
              onClick={(c) => {
                downloadInvoice(
                  e.row.original.accountnumber,
                  e.row.original.invoiceperiod,
                  bearerToken,
                  notyf
                );
                notyf.open({
                  type: "default",
                  message: "Download has been requested...",
                  duration,
                  ripple,
                  dismissible,
                  position: {
                    x: positionX,
                    y: positionY,
                  },
                });
              }}
            >
              <Button variant="outline-primary">{e.value}</Button>
            </Link>
          ) : (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-right">Not Available For Download</Tooltip>
              }
            >
              <Button variant="outline-secondary">{e.value}</Button>
            </OverlayTrigger>
          )}
        </div>
      ),
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Account Number",
      accessor: "accountnumber",
      isVisible: false,
    },
    {
      Header: "Invoice Period",
      accessor: "invoiceperiod",
    },
    {
      Header: "PDF Available",
      accessor: "pdf_available",
    },
    {
      Header: "Invoice Date",
      accessor: "invoicedate",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY"),
    },
    {
      Header: "Invoice Total",
      accessor: "invoicetotal",
      Cell: (e) => "£" + (e.value ? e.value.toFixed(2) : ""),
    },
  ];

  const getSearchType = (orgPermission, landlordPermission, allPermission) => {
    if (orgPermission[1]) return "invoicesByAccountNumber";
    if (landlordPermission[1] || allPermission[1]) return "invoicesByCtidList";
    return null;
  };

  const getSearchValue = (
    orgPermission,
    landlordPermission,
    allPermission,
    account,
    landlordId
  ) => {
    if (orgPermission[1]) return account;
    if (landlordPermission[1] || allPermission[1]) return landlordId;
    return null;
  };

  const defaultSearchTypeOrg = usePermissions("view:billing:org");
  const defaultSearchTypeLandlord = usePermissions("view:billing:landlord");
  const defaultSearchTypeAll = usePermissions("view:billing:all");

  const defaultSearchType = getSearchType(
    defaultSearchTypeOrg,
    defaultSearchTypeLandlord,
    defaultSearchTypeAll
  );
  const defaultSearchValue = getSearchValue(
    defaultSearchTypeOrg,
    defaultSearchTypeLandlord,
    defaultSearchTypeAll,
    selectedAccount.current_account,
    selectedLandlord.landlordid
  );

  useEffect(() => {
    if (isAuthenticated && defaultSearchType && defaultSearchValue) {
      const axiosConfig = {
        headers: { Authorization: bearerToken },
      };

      axios
        .get(
          `${apiConfig.symbillApiUrl}newsymbillapi/${defaultSearchType}/${defaultSearchValue}`,
          axiosConfig
        )
        .then((response) => {
          if (response.status === 204) {
            setLoading(false);
            setNoData(false);
            setInvoicesData([]);
            return;
          }
          const invoicesList = response.data.map((invoice) => ({
            invoiceref: invoice.invoiceNumber,
            customer: invoice.companyName,
            invoiceperiod: invoice.invoicePeriod,
            invoicedate: invoice.invoiceDate,
            invoicetotal: invoice.invoiceValue,
            accountnumber: invoice.accountNumber,
            pdf_available: invoice.pdfAvailable,
          }));
          setLoading(false);
          setInvoicesData(invoicesList);
        })
        .catch((error) => {
          setLoading(false);
          setNoData(true);
        });
    }
  }, [
    bearerToken,
    selectedLandlord,
    dispatch,
    defaultSearchType,
    defaultSearchValue,
    isAuthenticated,
  ]);
  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Invoice List</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && (
          <>
            <LongDataLoader />
          </>
        )}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnFilteringTable
            columns={invoicesTableColumns}
            data={invoicesData}
            hiddenColumns={["accountnumber", "pdf_available"]}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const BillingInvoicesList = () => {
  const navigate = useNavigate();
  return (
    <IsAllowed to="view:billing" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Billing" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Billing</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Billing
            <OffcanvasHelp id="billing" name="Billing" scroll backdrop />
          </h1>
          <div className="clearfix"></div>
          <IsAllowed to="view:billing:stats">
            <Row>{/* <BillingStats /> */}</Row>
          </IsAllowed>
          <Row>
            <Col xl="9">
              <InvoicesList />
            </Col>
            <Col xl="3">
              <GetSupport type="billing" />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default BillingInvoicesList;
