import React, { createContext, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { rolePermissions } from "./permissions/permissions";
import { appSettings } from "../config";

const defaultBehaviour = {
  isAllowedTo: false,
};

const PermissionsContext = createContext(defaultBehaviour);

export function PermissionsProvider({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      appSettings.maintenanceMode === "1" &&
      user.roles &&
      user.roles !== "superadmin"
    ) {
      navigate("/auth/maintenance");
    }
  }, [user.roles, navigate]);

  async function getUserRole() {
    return await user.roles;
  }

  const fetchPermission = async (permission) => {
    const userRole = await getUserRole();
    let foundPerm = (rolePermissions[userRole] || []).filter((role) => {
      return typeof role == "string" && role.indexOf(permission) > -1;
    });
    return foundPerm.length > 0 ? true : false;
  };

  const cache = {};

  const isAllowedTo = async (permission) => {
    if (Object.keys(cache).includes(permission)) {
      return cache[permission];
    }
    const isAllowed = await fetchPermission(permission);
    cache[permission] = isAllowed;
    return isAllowed;
  };

  return (
    <PermissionsContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionsContext.Provider>
  );
}

export default PermissionsContext;
