import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import Select from "react-select";
import { setSearchFilters } from "../../../redux/slices/filters";
import axios from "axios";
import { apiConfig } from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";

const SearchFilter = () => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [customerOptions, setCustomerOptions] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingCustomersError, setLoadingCustomersError] = useState(false);
  const [loadingBuildings, setLoadingBuildings] = useState(true);
  const [loadingBuildingsError, setLoadingBuildingsError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    setLoadingCustomers(true);
    setLoadingBuildings(true);
    axios
      .get(
        apiConfig.symbillApiUrl +
          "newsymbillapi/byCtidList/" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        let customersList = [];
        response.data.forEach((customer) => {
          customersList.push({
            value: customer.accountNumber,
            label: customer.companyName,
          });
        });
        setCustomerOptions(customersList);
        setLoadingCustomers(false);
        setLoadingCustomersError(false);
      })
      .catch(function (error) {
        setLoadingCustomers(false);
        setLoadingCustomersError(true);
      });
    axios
      .get(
        apiConfig.connectedApiUrl +
          "buildings?page_size=1000&order_by=building_name%20ASC&building_operator=" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        let buildingsList = [];
        response.data.buildings.items.forEach((building) => {
          buildingsList.push({
            value: building.wifi_id,
            label: building.building_name,
          });
        });
        setLoadingBuildings(false);
        setLoadingBuildingsError(false);
        setBuildingOptions(buildingsList);
      })
      .catch(function (error) {
        setLoadingBuildings(false);
        setLoadingBuildingsError(true);
      });
  }, [bearerToken, selectedLandlord]);

  const dispatch = useDispatch();
  return (
    <Card>
      <Card.Header>Search and Filter</Card.Header>
      <Card.Body>
        <Form.Group className="mb-3">
          <Form.Label>Company Name</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={customerOptions}
            isClearable={true}
            onChange={(e) => {
              setSelectedCustomer(e);
              setSelectedBuilding(null);
              dispatch(
                setSearchFilters({
                  search_type: e ? "account_number" : null,
                  search_value: e ? e.value : null,
                })
              );
            }}
            value={
              !loadingCustomers && loadingCustomersError
                ? { label: "Error while loading", value: "error" }
                : loadingCustomers && !loadingCustomersError
                ? { label: "Loading...", value: "loading" }
                : !loadingCustomers && !loadingCustomersError
                ? selectedCustomer
                : null
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Building</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={buildingOptions}
            isClearable={true}
            onChange={(e) => {
              setSelectedBuilding(e);
              setSelectedCustomer(null);
              dispatch(
                setSearchFilters({
                  search_type: e ? "building" : null,
                  search_value: e ? e.value : null,
                })
              );
            }}
            value={
              !loadingBuildings && loadingBuildingsError
                ? { label: "No buildings found", value: "error" }
                : loadingBuildings && !loadingBuildingsError
                ? { label: "Loading...", value: "loading" }
                : !loadingBuildings && !loadingBuildingsError
                ? selectedBuilding
                : null
            }
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default SearchFilter;
