import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import NumUsersDisplay from "../../../../components/wifi/NumUsersDisplay";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../../config";
import NotyfContext from "../../../../contexts/NotyfContext";

const AddNew8021XCredentialModal = ({
  openModals,
  toggle,
  customerOptions,
  dispatch,
  setRefreshData,
}) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const accessLevelOptions = [
    { value: 1, label: "Web & LAN" },
    { value: 0, label: "Web Only" },
  ];
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [excellid, setExcellId] = useState();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [accessLevelNum, setAccessLevelNum] = useState(0);
  const [numUsersIsLoading, setNumUsersIsLoading] = useState(true);
  const [numAvailable, setNumAvailable] = useState(0);
  const isCredentialFormComplete =
    excellid &&
    firstName &&
    lastName &&
    username &&
    email &&
    accessLevelNum !== null;

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    setEmail("");
    setAccessLevelNum(0);
  };

  const handleCreateCredentialSubmit = (event) => {
    event.preventDefault();
    const createCredentialBody = {
      username: username,
      firstname: firstName,
      lastname: lastName,
      email: email,
      excellid: excellid,
      userlevel: accessLevelNum,
      building_operator_id: selectedLandlord.landlordid,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(apiConfig.dot11ApiUrl + "users/", createCredentialBody, axiosConfig)
      .then(function (response) {
        if (response.data.success === 1) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };
  return (
    <Modal
      show={openModals["addNewCredential"]}
      onHide={() => {
        toggle("addNewCredential");
        resetFormFields();
      }}
      centered
    >
      <Form onSubmit={handleCreateCredentialSubmit}>
        <Modal.Header closeButton>
          <b>Admin Function:</b>&nbsp; Add New 802.1X Credential
        </Modal.Header>
        <Modal.Body className="m-3">
          <div className="text-left my-0">
            <Form.Group className="mb-3">
              <h3>Select an account to add a wi-fi user for:</h3>
              <Form.Label>Company Name</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={customerOptions}
                onChange={(e) => {
                  setExcellId(e.value);
                  resetFormFields();
                }}
                value={
                  excellid
                    ? customerOptions.find(
                        (option) => option.value === excellid
                      )
                    : null
                }
              />
            </Form.Group>
            {excellid ? (
              <NumUsersDisplay
                excellid={excellid}
                isLoading={numUsersIsLoading}
                setIsLoading={setNumUsersIsLoading}
                numAvailable={numAvailable}
                setNumAvailable={setNumAvailable}
              />
            ) : null}
            {!numUsersIsLoading && numAvailable > 0 && (
              <>
                <hr />
                <h3 className="mt-3">Provide details for the new user:</h3>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Access Level</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={accessLevelOptions}
                    onChange={(e) => setAccessLevelNum(e.value)}
                  />
                </Form.Group>
              </>
            )}
            {!numUsersIsLoading && numAvailable <= 0 && (
              <Alert className="my-3 p-2">
                This account does not have any available wi-fi users. &nbsp;
                <a
                  href="#"
                  onClick={() => {
                    toggle("addNewCredential");
                    toggle("wifiPurchase");
                  }}
                >
                  Request more.
                </a>
              </Alert>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggle("addNewCredential")}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="info"
            disabled={!isCredentialFormComplete}
            onClick={() => {
              toggle("addNewCredential");
              setTimeout(() => dispatch(setRefreshData()), 2000);
            }}
          >
            Create Credential
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddNew8021XCredentialModal;
