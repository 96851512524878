import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import OffcanvasHelp from "../../../components/OffcanvasHelp";

import IncidentTicketsTable from "./IncidentTicketsTable";
import NetworkSLABar from "./NetworkSLABar";
import TicketStats from "./TicketStats";
import Welcome from "./Welcome";
import TopOutstandingTicketsTable from "./TopOutstandingTicketsTable";
import IsAllowed from "../../../components/IsAllowed";
import NotAuthorised from "../../../components/NotAuthorised";
import usePermissions from "../../../hooks/usePermissions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";

const Default = () => {
  const navigate = useNavigate();
  const allowedDefault = usePermissions("view:overview:default");
  const allowedTenant = usePermissions("view:overview:tenant");
  const isPageLoading = useSelector((state) => state.pageloading);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(isPageLoading.loading);
  }, [isPageLoading]);
  useEffect(() => {
    if (selectedLandlord && selectedLandlord.landlordid === 42 && !loading) {
      navigate("/credentials/dot11");
    }
  }, [selectedLandlord, navigate, loading]);
  return (
    <>
      {loading && <LogoLoader className="d-block m-auto p-5" />}
      {!loading && (
        <>
          <IsAllowed to="view:overview:default" fallback={<NotAuthorised />}>
            {allowedTenant[1] && !allowedDefault[1]
              ? navigate("/customers/orgProfile")
              : ""}
            <React.Fragment>
              <Helmet title="Overview Dashboard" />
              <Container fluid className="p-0">
                <Welcome />
                <Row>
                  <h3 className="m-3">
                    Networks Overview
                    <OffcanvasHelp
                      id="networksoverview"
                      name="Network Overview"
                      scroll
                      backdrop
                    />
                  </h3>
                </Row>
                <Row>
                  <Col lg="12" xl="12" className="d-flex">
                    <NetworkSLABar />
                  </Col>
                </Row>
                <Row>
                  <h3 className="m-3">
                    Tickets Overview
                    <OffcanvasHelp
                      id="ticketsoverview"
                      name="Tickets Overview"
                      scroll
                      backdrop
                    />
                  </h3>
                  <TicketStats />
                  <Col lg="6" xl="6" className="d-flex">
                    <TopOutstandingTicketsTable />
                  </Col>
                  <Col lg="6" xl="6" className="d-flex">
                    <IncidentTicketsTable />
                  </Col>
                </Row>
              </Container>
            </React.Fragment>
          </IsAllowed>
        </>
      )}
    </>
  );
};

export default Default;
