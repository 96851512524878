import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { apiConfig } from "../../../config";
import useAuth from "../../../hooks/useAuth";

function resolve(path, obj) {
  if (path === ".") {
    return obj;
  }
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
    /* eslint-disable-next-line no-restricted-globals */
  }, obj || self);
}

const FilterForm = ({ eventTypeMetadata, eventType, onAddFilter }) => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const [selectedField, setSelectedField] = useState(null);
  const [operator, setOperator] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [dropdownValues, setDropdownValues] = useState([]);

  const resetState = () => {
    setSelectedField(null);
    setOperator("");
    setFilterValue("");
    setDropdownValues([]);
  };

  useEffect(() => {
    if (!eventType && !eventTypeMetadata[eventType]) {
      return;
    } else {
      //
    }
    const axiosConfig = { headers: { Authorization: bearerToken } };
    if (
      selectedField?.input_type === "select" &&
      selectedField?.values_source?.type === "dynamic"
    ) {
      axios
        .get(
          `${apiConfig[selectedField.values_source.api.api + "ApiUrl"]}${
            selectedField.values_source.api.url
          }`,
          axiosConfig
        )
        .then((response) => {
          const values = resolve(
            selectedField.values_source.api.list_path,
            response.data
          );
          setDropdownValues(
            values.map((value) => ({
              value: value[selectedField.values_source.api.value_field],
              label: value[selectedField.values_source.api.label_field],
            }))
          );
        })
        .catch((error) => {});
    } else {
      setDropdownValues([
        { label: "error", value: "error" },
        { label: "warning", value: "warning" },
        { label: "critical", value: "critical" },
      ]);
    }
  }, [selectedField, eventType, bearerToken, eventTypeMetadata]);

  const handleAddFilter = () => {
    const filter = {
      accessor: selectedField.accessor,
      operator,
      value: filterValue,
      label: selectedField.field_label,
    };
    onAddFilter(filter);
    resetState();
  };

  return (
    <Row className="p-1 mb-3">
      <Col xs="4">
        <Select
          className="react-select-container w-100"
          classNamePrefix="react-select"
          options={eventTypeMetadata[eventType].map((field) => ({
            value: field.accessor,
            label: field.field_label,
          }))}
          placeholder="Select field"
          onChange={(e) =>
            setSelectedField(
              eventTypeMetadata[eventType].find(
                (field) => field.accessor === e.value
              )
            )
          }
        />
      </Col>
      <Col xs="3" className="text-center text-lg">
        <Select
          className="react-select-container w-100"
          classNamePrefix="react-select"
          options={selectedField?.operators.map((op) => ({
            value: op,
            label: op,
          }))}
          placeholder=""
          value={{ value: operator, label: operator }}
          onChange={(e) => setOperator(e.value)}
          isDisabled={!selectedField}
          isSearchable={false}
        />
      </Col>
      <Col xs="4">
        {selectedField?.input_type === "select" ? (
          <Select
            className="react-select-container w-100"
            classNamePrefix="react-select"
            options={dropdownValues}
            placeholder=""
            onChange={(e) => setFilterValue({ value: e.value, label: e.label })}
            isDisabled={
              !selectedField ||
              (selectedField.values_source.type === "api" &&
                dropdownValues.length === 0)
            }
          />
        ) : (
          <Form.Control
            type={selectedField?.input_type === "number" ? "number" : "text"}
            placeholder=""
            value={filterValue.label || ""}
            onChange={(e) =>
              setFilterValue({ value: e.target.value, label: e.target.value })
            }
            disabled={!selectedField}
            style={{ height: "38px" }}
          />
        )}
      </Col>
      <Col xs="1">
        <FontAwesomeIcon
          icon={faCirclePlus}
          className={
            "text-lg align-middle m-auto text-primary" +
            (selectedField && operator && filterValue ? "" : "text-muted")
          }
          onClick={
            selectedField && operator && filterValue ? handleAddFilter : null
          }
        />
      </Col>
    </Row>
  );
};

export default FilterForm;
