import { createContext, useEffect, useReducer } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";

import { auth0Config } from "../config";

import { useDispatch } from "react-redux";
import { setLandlord } from "../redux/slices/landlordswitcher";
import { setAccount } from "../redux/slices/accountswitcher";
import { setPageLoading } from "../redux/slices/pageloading";
import axios from "axios";
import { apiConfig } from "../config";

import Loader from "../components/Loader";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, userAccessToken } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userAccessToken,
    };
  }
  if (action.type === SIGN_IN) {
    const { user, userAccessToken } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      userAccessToken,
    };
  }

  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      userAccessToken: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchLandlord = useDispatch();
  const dispatchAccount = useDispatch();
  const dispatchPageLoading = useDispatch();
  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          clientId: auth0Config.clientId || "",
          domain: auth0Config.domain || "",
          useRefreshTokens: true,
          useRefreshTokensFallback: true,
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: "https://gateway.wavenet.co.uk",
          },
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const accessToken = await auth0Client?.getTokenSilently();
          const user = await auth0Client.getUser();
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated,
              user: user || null,
              userAccessToken: accessToken || null,
            },
          });

          let axiosConfig = {
            headers: { Authorization: `Bearer ${accessToken}` },
          };
          const buildingOperators = await axios.get(
            apiConfig.connectedApiUrl + `operators?page_size=1000`,
            axiosConfig
          );
          let defaultLandlord =
            user["https://gateway.wavenet.co.uk/landlordid"][0] === 42
              ? 2
              : user["https://gateway.wavenet.co.uk/landlordid"][0];
          const operatorName =
            user["https://gateway.wavenet.co.uk/landlordid"][0] === 42
              ? [{ operator_name: "Workspace Group" }]
              : buildingOperators.data.operators.items.filter((operator) => {
                  return operator.id === defaultLandlord;
                });
          dispatchAccount(
            setAccount({
              current_account:
                user["https://gateway.wavenet.co.uk/accountnumber"][0],
              accounts: user["https://gateway.wavenet.co.uk/accountnumber"],
            })
          );
          dispatchLandlord(
            setLandlord({
              id: defaultLandlord,
              name: operatorName[0].operator_name,
              ctids: operatorName[0].customer_type_id,
            })
          );

          dispatchPageLoading(
            setPageLoading({
              isPageLoading: false,
            })
          );
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initialize();

    const intervalId = setInterval(async () => {
      try {
        const accessToken = await auth0Client.getTokenSilently();
        const user = await auth0Client.getUser();

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: user || null,
            userAccessToken: accessToken || null,
          },
        });
      } catch (error) {}
    }, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatchLandlord, dispatchPageLoading, dispatchAccount]);

  const signIn = async () => {
    await auth0Client?.loginWithPopup();
    const isAuthenticated = await auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      const accessToken = await auth0Client?.getTokenSilently();
      const user = await auth0Client?.getUser();
      dispatch({
        type: SIGN_IN,
        payload: {
          user: user || null,
          userAccessToken: accessToken || null,
        },
      });
      let axiosConfig = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const buildingOperators = await axios.get(
        apiConfig.connectedApiUrl + `operators?page_size=1000`,
        axiosConfig
      );
      let defaultLandlord =
        user["https://gateway.wavenet.co.uk/landlordid"][0] === 42
          ? 2
          : user["https://gateway.wavenet.co.uk/landlordid"][0];
      const operatorName = buildingOperators.data.operators.items.filter(
        (operator) => {
          return operator.id === defaultLandlord;
        }
      );
      dispatchAccount(
        setAccount({
          current_account:
            user["https://gateway.wavenet.co.uk/accountnumber"][0],
          accounts: user["https://gateway.wavenet.co.uk/accountnumber"],
        })
      );
      dispatchLandlord(
        setLandlord({
          id: defaultLandlord,
          name: operatorName[0].operator_name,
          ctids: operatorName[0].customer_type_id,
        })
      );

      dispatchPageLoading(
        setPageLoading({
          isPageLoading: false,
        })
      );
    }
  };

  const signOut = () => {
    auth0Client?.logout({
      returnTo: auth0Config.returnUrl,
    });
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => {};

  const getUserMetadata = async () => {
    const user = await auth0Client?.getUser();
    try {
      const accessToken = await auth0Client?.getTokenSilently();
      try {
        const result = await fetch(
          "https://" + auth0Config.domain + "/api/v2/users/" + user.sub,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        const data = await result.json();
        return data;
      } catch (err) {}
    } catch (err) {}
  };

  const refreshUser = async () => {
    const accessToken = await auth0Client?.getTokenSilently({
      cacheMode: "off",
    });
    const user = await auth0Client?.getUser();
    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user: user || null,
        userAccessToken: accessToken || null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "auth0",
        user: {
          id: state?.user?.sub,
          avatar: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.name,
          roles: state?.user?.["https://gateway.wavenet.co.uk/roles"][0],
          accountnumber:
            state?.user?.["https://gateway.wavenet.co.uk/accountnumber"],
          landlordid:
            state?.user?.["https://gateway.wavenet.co.uk/landlordid"][0],
          userAccessToken: state?.userAccessToken,
          mobile: state?.user?.["https://gateway.wavenet.co.uk/mobile"] || "",
        },
        signIn,
        signOut,
        resetPassword,
        getUserMetadata,
        refreshUser,
      }}
    >
      {state.isAuthenticated && !state.user ? <Loader /> : children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
