import React from "react";

import { Card } from "react-bootstrap";
import DeviceStats from "./DeviceStats";

const DisplayDeviceStats = ({ data, selectedDevice }) => {
  if (!selectedDevice.inventorySource) {
    return (
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title className="mb-0">Device Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <span className="d-block mx-auto p-3 text-center">
            Select a device to view device details and stats
          </span>
        </Card.Body>
      </Card>
    );
  } else {
    return <DeviceStats data={data} selectedDevice={selectedDevice} />;
  }
};

export default DisplayDeviceStats;
