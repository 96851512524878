import React from "react";

import { Dropdown } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { setAccount } from "../../redux/slices/accountswitcher";

const NavbarAccountSwitcher = () => {
  const selectedAccount = useSelector((state) => state.accountswitcher);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown className="nav-item me-3" align="end">
        <span className="d-none d-sm-inline-block">
          {selectedAccount?.accounts?.length > 1 && (
            <Dropdown.Toggle as="a" className="nav-link">
              <span className="text-dark mx-2">
                {selectedAccount?.current_account}
              </span>
            </Dropdown.Toggle>
          )}
          {selectedAccount?.accounts?.length <= 1 && (
            <Dropdown as="a" className="nav-link">
              <span className="text-dark mx-2">
                {selectedAccount?.current_account}
              </span>
            </Dropdown>
          )}
        </span>
        {selectedAccount?.accounts?.length > 1 && (
          <Dropdown.Menu drop="end">
            {selectedAccount.accounts.map((account) => (
              <Dropdown.Item
                key={account}
                onClick={() => {
                  dispatch(
                    setAccount({
                      current_account: account,
                      accounts: selectedAccount.accounts,
                    })
                  );
                  navigate("/dashboard/default");
                }}
              >
                {account}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

export default NavbarAccountSwitcher;
