import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment";

import WorkflowStatusBadge from "./WorkflowStatusBadge";
import ColumnSortingTable from "../../components/tables/AutomationResultInventoryTable";
import NetworkAutomationHostResult from "./NetworkAutomationHostResult";

const NetworkAutomationResult = ({ passedResult }) => {
  const [selectedDevice, setSelectedDevice] = useState({
    hostname: null,
    id: null,
  });

  let workflowData = {};
  if (!passedResult) {
    return null;
  } else {
    try {
      workflowData = {
        ...passedResult,
        manifest: JSON.parse(passedResult.manifest),
        result: JSON.parse(passedResult.result),
      };
    } catch (err) {
      return null;
    }
  }

  if (!workflowData || !workflowData.result) {
    return null;
  }

  const handleRowClick = (rowInfo) => {
    setSelectedDevice({
      hostname: rowInfo.original.hostname,
      id: rowInfo.original.id,
    });
  };

  const hostData = workflowData.result
    .map((workflow, index) => {
      if (workflow.host_results.length === 0) {
        return null;
      }
      return {
        id: index,
        hostname: workflow.host_results[0].host.hostname,
      };
    })
    .filter(Boolean);

  const inventoryColumns = [
    {
      Header: "Hostname",
      accessor: "hostname",
      sort: true,
    },
    {
      name: "id",
      accessor: "id",
    },
  ];

  return (
    <>
      <Row>
        <Col md={4} className="border-end">
          <h4 className="m-1">Workflow Details</h4>
          <Table size="sm" className="my-2">
            <tbody>
              <tr>
                <th>Workflow</th>
                <td>{workflowData.workflow_name}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <WorkflowStatusBadge status={workflowData.status} />
                </td>
              </tr>
              <tr>
                <th>Launched by</th>
                <td>{workflowData.created_by}</td>
              </tr>
              <tr>
                <th>Launched on</th>
                <td>
                  {workflowData.created_at
                    ? moment(workflowData.created_at).format(
                        "HH:mma on Do MMMM YYYY "
                      )
                    : ""}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={4} className="border-end">
          <h4 className="m-1">Customer Details</h4>
          <Table size="sm" className="my-2">
            <tbody>
              <tr>
                <th>Customer Account</th>
                <td>{workflowData.customer}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{workflowData.location}</td>
              </tr>
              <tr>
                <th>Building</th>
                <td>{workflowData.manifest.tenancyDetails.buildingId}</td>
              </tr>
            </tbody>
          </Table>
        </Col>

        <Col md={4}>
          <h4 className="m-1">Service Details</h4>
          {workflowData.manifest.services.map((service) => (
            <Table size="sm" className="my-2">
              <tbody>
                <tr>
                  <th>Service Type</th>
                  <td>{service.service}</td>
                </tr>
                <tr>
                  <th>VLAN</th>
                  <td>{service.params.vlan}</td>
                </tr>
                <tr>
                  <th>X-Connect?</th>
                  <td>{service.params.xconnect ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>VLAN Assignment Type</th>
                  <td>
                    {workflowData.manifest.vlanAssignment.type ===
                    "existing_assignment"
                      ? "Existing"
                      : "New"}
                  </td>
                </tr>
                <tr>
                  <th>Static IP Address?</th>
                  <td>{service.params.staticIp ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
          ))}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4 className="m-1">Automation Results</h4>
          <Row>
            <Col md={3} className="border-end pe-2">
              <ColumnSortingTable
                columns={inventoryColumns}
                data={hostData}
                onRowClick={handleRowClick}
                selectedDevice={selectedDevice}
                hiddenColumns={["id"]}
              />
            </Col>
            <Col md={9}>
              <NetworkAutomationHostResult
                selectedDevice={selectedDevice}
                workflowData={workflowData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default NetworkAutomationResult;
