import React from "react";
import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Dot11Stats from "./components/Dot11Stats";
import Dot11CredentialsList from "./components/Dot11CredentialsList";
import SearchFilter from "./components/SearchFilter";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import GetSupport from "../../components/GetSupport";
import NotAuthorised from "../../components/NotAuthorised";
import IsAllowed from "../../components/IsAllowed";
import usePermissions from "../../hooks/usePermissions";
import { useNavigate } from "react-router-dom";

const ManageDot11Credentials = () => {
  let showSearchFilters = false;
  const hasLandlordPermissions = usePermissions("view:credentials:landlord")[1];
  const hasAllPermissions = usePermissions("view:credentials:all")[1];
  if (hasLandlordPermissions || hasAllPermissions) {
    showSearchFilters = true;
  }
  const navigate = useNavigate();
  return (
    <IsAllowed to="view:credentials" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Wi-Fi Credentials" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Wi-Fi Credentials</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Wi-Fi Credentials
            <OffcanvasHelp
              id="wi-fi"
              name="Wi-Fi Credentials"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix"></div>
          <IsAllowed to="view:credentials:stats">
            <Row>
              <Dot11Stats />
            </Row>
          </IsAllowed>
          <Row>
            <Col xl="9">
              <Dot11CredentialsList />
            </Col>
            <Col xl="3">
              {showSearchFilters && <SearchFilter />}
              <GetSupport type="wifi" />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default ManageDot11Credentials;
